import PropTypes from 'prop-types';

const ReusableInputField = ({ label, id, placeholder, value, onChange }) => {
    return (
      <div className="flex  items-center items-start justify-start mt-4">
        <p className="Label text-left uppercase mt-5 text-white w-32" style={{ fontSize: "14px", fontWeight: 300 }}>
          {label}
        </p>
        <div className="lg:ml-[30px]"> {/* Adjusted margin for small screens */}
          <input
            className="font-inter w-full bg-gray_900 p-2 text-login-label border-b-[1px] bg-transparent"
            type="text"
            id={id}
            name={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };
  ReusableInputField.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string, // Add this line for the placeholder prop
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  export default ReusableInputField;