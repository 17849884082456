// EmailPasswordLogin.js
import React, { useState } from 'react';
import { FaEye, FaEyeSlash} from 'react-icons/fa';
import { MdArrowForwardIos } from "react-icons/md";
import { Link,useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase';
import {apiAxiosForLogin} from "../../api/axiosConfig";
import {
    signInWithEmailAndPassword,
  } from "firebase/auth";
import GoogleLogin from './GoogleLogin';
import toast from 'react-hot-toast';
import emailValidator from 'email-validator';

const EmailPasswordLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate=useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  const handleLogin = async () => {
    // Basic validation for non-empty fields
    if (!validateNonEmptyFields(email, password)) {
      return;
    }
  
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    if (!isValidPassword(password)) {
      setError('Password must be at least 8 characters long and include letters and numbers.');
      return;
    }
  
    try {
      setError('');
      const response = await signInWithEmailAndPassword(auth, email, password);
  
      if (response != null) {
        handleLoginSuccess(response);
      }
    } catch (error) {
      handleLoginError(error);
    }
  };
  
  const validateNonEmptyFields = (email, password) => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return false;
    }
    return true;
  };
  
  const handleLoginSuccess = (response) => {
    const idToken = response._tokenResponse.idToken;
    const requestData = {
      id_tkn: idToken,
      firebase_notification_tkn: "firebase_notification_tkn",
    };
  
    if (idToken != null) {
      localStorage.setItem("idToken", idToken);
      apiAxiosForLogin.post("/user/login", requestData)
        .then((response) => {
          let message = response.data.message;
          let tokenDetails = response.data.data;
  
          if (message === "You have successfully logged in!") {
            localStorage.setItem("accessToken", tokenDetails.access_token);
            localStorage.setItem("loginUserName", response.data.data.profile.name);
            localStorage.setItem("fuidId", response.data.data.profile.fuid);
  
            toast.success('Logged in successfully!');
            navigate('/dashboard/tenant');
          }
        });
    }
  };
  
  const handleLoginError = (error) => {
    toast.error('Error logging in:', error.message);
  };
  
  const isValidEmail = (email) => {
    return  emailValidator.validate(email);
  };
  
  const isValidPassword = (password) => {
    return password.length >= 8 && /[a-zA-Z]/.test(password) && /\d/.test(password);
  };
  
  return (
    
    <div className="flex items-center justify-center h-screen">
    <div className="max-w-md mx-auto mt-8 p-6 ">
        <h2 className='text-white font-bold ' 
         style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
        >Login to ViXR</h2>
        <br/><br/>
     
        <div className="mb-4">
          <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
            Email
          </label>
          <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}  className="w-full p-2 text-white border-b-[2px] bg-transparent" />
        </div>
        <div className="mb-4">
          <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
           Password
          </label>
          <div className='relative'>
          <input type={showPassword ? 'text' : 'password'} placeholder="Password" onChange={(e) => setPassword(e.target.value)}  className="w-full p-2 text-white border-b-[2px] bg-transparent"/>
          <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-transparent border-none cursor-pointer focus:outline-none"
      >
        {showPassword ?  <FaEye size={20} className='text-white' /> : <FaEyeSlash size={20} className='text-white'/>}
      </button>
      </div>
        </div>
        <Link to="/forgot-password"><p className='text-default-button underline'>Reset Password</p></Link>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <p className='text-login-label text-sm mb-4'>By proceeding you confirm to Agree to ViXR Terms of Use</p>
        <button className="bg-default-button text-white p-2 rounded-md w-full button-sm" onClick={handleLogin}>Login</button>
     

      {/* Login with Another Method */}
      <div className="mt-4">
      <p className="text-white text-center mb-4">Login using another method</p>
      <div className="flex flex-row items-center justify-center">
      <GoogleLogin />
      </div>
     
       
        <div className="flex items-center justify-center">
          <Link
          to="/signup"
          className="inline-block text-white hover:underline focus:outline-none mt-10 text-center"
          
        >
           Don't Have an Account? SignUp
          </Link>
          <MdArrowForwardIos size={20}  color={"white"} className='ml-2 mt-10'/>
          <p id="stellar_copyright"className='text-white  absolute bottom-0 '>© 2023 ViXR Inc.</p>
        </div>
       
       
      </div>

    
    </div>
    
    </div>
  );
};

export default EmailPasswordLogin;
