import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import { IoMdBriefcase } from "react-icons/io";
import { MdStorage, MdAnalytics } from "react-icons/md";
import { FaUser, FaRegBuilding, FaDatabase } from "react-icons/fa";
import HamburgerMenu from "../HambergurMenu";
import { useMenuContext } from "../../context/MenuContext";
import PropTypes from 'prop-types';

const SidebarLink = ({ to, icon: Icon, label, isActive, isMenuOpen }) => {
  return (
    <li className={`mb-2 hover:bg-gray-600 p-2 ${isActive ? 'bg-gray-600' : ''}`}>
      <Link to={to} exact className={`flex text-xl hover:text-gray-300 ml-2 ${isActive ? 'bg-blue text-white' : ''}`}>
        <Icon className="text-2xl md:text-3xl lg:text-4xl  mr-2" />
        {isMenuOpen ? null : <span>{label}</span>}
      </Link>
    </li>
  );
};

const Sidebar = ({ showLogout }) => {
  const location = useLocation();
  const matchpath = location.pathname.split('/').filter(Boolean).pop();
  const { isMenuOpen } = useMenuContext(); // Define isMenuOpen here

  return (
    <div className="flex flex-col h-screen">
      {/* Top Section */}
      <div className="flex justify-between bg-header-bar text-white p-2">
        <h1 className="text-logo-color" style={{ fontWeight: '300px', fontSize: '36px' }}>ViXR</h1>
        <HamburgerMenu />
      </div>

      {/* Middle Section */}
      <div className="text-white p-4">
        <nav>
          <ul>
            {/* Pass isMenuOpen to SidebarLink component */}
            <SidebarLink to="analytics" icon={MdAnalytics} label="Analytics" isActive={matchpath === 'analytics'} isMenuOpen={isMenuOpen} />
            <SidebarLink to="tenant" icon={FaRegBuilding} label="Organization" isActive={matchpath === 'tenant'} isMenuOpen={isMenuOpen} />
            <SidebarLink to="platform-asset" icon={MdStorage} label="Assets" isActive={matchpath === 'platform-asset'} isMenuOpen={isMenuOpen} />
            <SidebarLink to="platform-experience" icon={IoMdBriefcase} label="Experience" isActive={matchpath === 'platform-experience'} isMenuOpen={isMenuOpen} />
            <SidebarLink to="user-profile" icon={FaUser} label="Profile" isActive={matchpath === 'user-profile'} isMenuOpen={isMenuOpen} />
            <SidebarLink to="mongoData" icon={FaDatabase} label="DB Management" isActive={location.pathname === "/dashboard/mongoData"} isMenuOpen={isMenuOpen} />
            <li className={`mb-2 hover:bg-gray-600 p-4`}>
              <Link to="#" onClick={() => showLogout()} exact className="flex text-xl hover:text-gray-300 ml-2">
                <IoLogOut className="text-3xl md:text-4xl lg:text-4xl  mr-2" />
                {isMenuOpen ? null : <span>Logout</span>}
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Bottom Section */}
      {isMenuOpen ? null : (
        <div className="text-white pl-8 pb-4 mt-auto overflow-hidden">
          <div className="flex flex-col ">
            <Link to="/privacy-policy" className="hover:text-gray-300 mb-2">Privacy Policy</Link>
            <Link to="/contacts" className="hover:text-gray-300 mb-2">Contact</Link>
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@vixr.io" rel="noreferrer" target="_blank">
              <button className="bg-default-button text-white py-2 px-4 rounded mr-4 mb-2">SUPPORT</button>
            </a>
            <p className="text-white">© 2023 Vixr Inc.</p>
          </div>
        </div>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  showLogout: PropTypes.func.isRequired,
};

export default Sidebar;