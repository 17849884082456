// HamburgerMenu.js
import React from 'react';
import { FaBars} from 'react-icons/fa';
import {  RiCloseLine } from 'react-icons/ri';
import { useMenuContext } from '../context/MenuContext';

const HamburgerMenu = () => {
  const { isMenuOpen, toggleMenu } = useMenuContext();

  return (
    <button className="hamburger-menu pt-4" 
    onClick={toggleMenu}>
      {isMenuOpen ? <RiCloseLine className="text-white text-2xl cursor-pointer" /> : <FaBars className="text-white text-2xl cursor-pointer" />}
    </button>
  );
};

export default HamburgerMenu;
