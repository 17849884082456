import React from 'react';
import { Outlet } from 'react-router-dom';

function LoginRightPanel() {
    return (
        <div className="m-0 w-full lg:w-2/6 bg-right-panel overflow-auto"> 
           <h1 className="md:hidden text-logo-color ml-2"  style={{ fontWeight: '300px', fontSize: '36px' }}>ViXR</h1>
            <Outlet/>
        </div>
    );
}

export default LoginRightPanel;