import React,{useState} from 'react';
import { Link,useNavigate } from "react-router-dom";
import LogoutModal from '../components/Logout/LogoutModel';
import { auth} from '../firebase/firebase';
import { signOut } from 'firebase/auth';
function MoreContent() {
    const navigate=useNavigate();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const handleLogout = async() => {
      try {
       
        await signOut(auth);
        console.log('User logged out successfully');
        // You can also redirect to a different page after logout if needed.
      } catch (error) {
        console.error('Error during logout:', error);
      }
      localStorage.removeItem('accessToken');
        localStorage.clear();
        navigate('/', {replace: true});
       
        // Close the modal
        setIsLogoutModalOpen(false);
      };
      const showLogout=()=>{
        setIsLogoutModalOpen(true);
      }
    return (
        <div className="block lg:hidden flex flex-col justify-center">
          <div className="flex flex-col text-white mb-4 p-8 w-full max-w-screen-xl text-xl">
            <ul className="space-y-4">
              <li>
                <Link to="/privacy-policy" className="hover:text-gray-300">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/contacts" className="hover:text-gray-300">
                  Contact
                </Link>
              </li>
              <li>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=support@vixr.io"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  Support
                </a>
              </li>
              <li>
              <button  className="cursor-pointer hover:text-gray-300" onClick={showLogout}>Logout</button>
               
              </li>
            </ul>
         
          </div>
          <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onLogout={handleLogout}
      />
       <div className="flex items-center justify-center mt-4">
              <p id="stellar_copyright" className="text-white absolute bottom-20">
                © 2023 ViXR Inc.
              </p>
            </div>
        </div>
      );
    };


export default MoreContent;