import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { api2 } from "../../api/axiosConfig";
const DynamicDropdown = ({ dropdownType }) => {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [platformAssets, setPlatformAssets] = useState([]);
  const [platformExperiences, setPlatformExperiences] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await api2.post("/tenantK/listPlatformResource");
        if (dropdownType === "Platform Assets") {
          setPlatformAssets(response.data.data.platform_assets);
         
        } else {
          setPlatformExperiences(response.data.data.platform_experiences);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [dropdownType]); // The empty dependency array ensures this effect runs only once on component mount

  const handlePlatformChange = (e) => {
    // Handle dropdown value change
    setSelectedPlatform(e.target.value);
  };

  return (
    <div className=" p-10">
      <div className="mb-10">
        <label className=" text-sm font-medium text-gray-500">
          Organization {dropdownType}
        </label>
      </div>

      <select
        value={selectedPlatform}
        onChange={handlePlatformChange}
        className="px-30 py-6 w-80 bg-transparent border rounded-md focus:outline-none focus:ring focus:border-blue-300"
      >
        <option value="" disabled hidden>
          {dropdownType}
        </option>
        {dropdownType === "Platform Assets"
          ? platformAssets.map((item) => (
              <option
                className="text-white bg-right-panel"
                key={item.name}
                value={item.name}
              >
                {item.name}
              </option>
            ))
          : platformExperiences.map((item) => (
              <option
                className="text-white bg-right-panel"
                key={item.name}
                value={item.name}
              >
                {item.name}
              </option>
            ))}
      </select>
    </div>
  );
};

DynamicDropdown.propTypes = {
  dropdownType: PropTypes.string.isRequired,
};
export default DynamicDropdown;
