import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function SignupFormWithOrganizationPlan(props) {
  return (
    <div>
      <div className="mt-10 flex flex-col justify-center h-screen">
        <div className="max-w-md mx-auto mt-20 p-2 ">
          <h2 className="text-white text-center font-bold text-xl lg:text-2xl">
            Let us complete creating your organization
          </h2>

          <form>
            <div className="flex flex-col items-center justify-start  p-2  mt-2">
            
            <p className='text-white mb-4'>Tenant Members</p>
            <Slider className="mb-4" min={5} max={100} defaultValue={50} marks={{ 5: 5, 100: 100 }}/>
           
           
            <br/>
             <div className='flex flex-row mb-4'>
              <div className="ml-4">
              <div className="inline-block bg-white text-center justify-center text-grey-button border-grey-button border-[1px] p-5 rounded-xl cursor-pointer" >
                  <p>PLAN A</p> 
                  <p>Pricing Details BASIC</p>
                </div>
              </div>
              <div className="ml-4">
                <div className="inline-block bg-white text-center justify-center text-grey-button border-grey-button border-[1px]  p-5 rounded-xl cursor-pointer" >
                  <p>PLAN B</p> 
                  <p>Pricing Details BASIC</p>
                </div>
              </div>
              <div className="ml-4">
                <div className="inline-block bg-white text-center justify-center text-grey-button border-grey-button border-[1px]  p-5 rounded-xl cursor-pointer" >
                  <p>PLAN C</p> 
                  <p>Pricing Details PRO</p>
                </div>
              </div>
              </div>
            </div>
            <p className="text-grey-label p-2 text-left mb-4">For MORE THAN 100 LICENSES (ENTERPRISE LICENSE), CONTACT US FOR CUSTOM PRICING</p>
          
            <div className="flex items-center justify-center">
              <Link to="/signup-add-organization-theme">
                <button className=" ml-4 bg-grey-button  text-white h-10 py-2 px-6 rounded ">
                  Back
                </button>
              </Link>
              <Link to="/signup-finished">
                <button className=" ml-4 bg-default-button text-white h-10 py-2 px-6 rounded ">
                  Next
                </button>
              </Link>
              <p
                id="stellar_copyright"
                className="text-white  absolute bottom-0 "
              >
                © 2023 ViXR Inc.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupFormWithOrganizationPlan;