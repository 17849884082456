import React from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
function OrganizationDetail() {
    const location = useLocation();
    const matchpath=location.pathname.split('/').filter(Boolean).pop();
     
    return (
        <div>
        {/* Render your data here */}
        <ul>
        <li className=' flex m-2 text-grey-button'>
               <p className={`flex-1 ${matchpath === 'profile' ? 'text-white' : 'text-grey-label'}`}>
                <Link to="profile">PROFILE</Link></p>
                <p className={`flex-1 ${matchpath === 'theme' ? 'text-white' : 'text-grey-label'}`}>
                <Link to="theme">THEME</Link></p>
                <p className={`flex-1 ${matchpath === 'constraints' ? 'text-white' : 'text-grey-label'}`}>
                <Link to="constraints">CONSTRAINTS</Link> </p>
                <p className={`flex-1 ${matchpath === 'resource' ? 'text-white' : 'text-grey-label'}`}>
                <Link to="resource">PLATFORM RESOURCES</Link></p>
              </li>
        </ul>
        <hr className='w-full border-left-panel border-t-2 shadow-md mb-4'/>
        <Outlet/>
        </div>
    );
}

export default OrganizationDetail;