import React from 'react';
import { Link } from 'react-router-dom';
function SignupFormWithOrganizationTheme(props) {
    return (
        <div>
        <div className="flex  justify-center h-screen">
    <div className="max-w-md mx-auto mt-20 p-2 ">
        <h2 className='text-white text-center font-bold ' 
         style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
        >Let us complete creating your organization</h2>
        
      <form>
      <div className="flex flex-col items-center justify-start  p-2  mt-2">
           <p className='text-grey-label p-8'>
                Logo
              </p>

              <div className=" ml-4">
              <div htmlFor="fileInput" className="inline-block  border-grey-button border-[1px]  px-10 py-20 rounded-xl cursor-pointer">
               <span className='underline text-grey-button'>Browse Here</span>
               <input type="file" id="fileInput" className="hidden"/>
             </div>
              </div>
            </div>
            <p className='text-grey-label p-2'>Theme Colour</p>
            <div className='flex p-4'>
            <div className="flex flex-row items-start justify-start    mt-2">
              <div className=" ml-4">
                <p className='text-left text-sm text-grey-button'>Primary Colour</p>
                <input
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_name"
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start    mt-2">
              <div className=" ml-4">
                <p className='text-left text-sm text-grey-button'>Secondary Colour</p>
                <input
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_name"
                />
              </div>
            </div>
             </div>
            <div className='flex items-center justify-center'>
          <Link to="/signup-add-organization-detail"><button className=' ml-4 bg-grey-button  text-white h-10 py-2 px-6 rounded '>Back</button></Link>
          <Link to="/signup-add-organization-plan"><button className=' ml-4 bg-default-button text-white h-10 py-2 px-6 rounded '>Next</button></Link>
          <p id="stellar_copyright" className='text-white  absolute bottom-0 sm:text-center sm:w-full'>© 2023 ViXR Inc.</p>
         </div>
      </form>
  
      </div>
    
    </div>
    </div>
    );
}

export default SignupFormWithOrganizationTheme;