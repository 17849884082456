import React, { useEffect, useState } from "react";
import { api2 } from "../api/axiosConfig";
import toast from "react-hot-toast";

function PlatformAssets() {
  const [assets, setAssets] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await api2.post("/tenant/listPlatformResource");
        setAssets(response.data.data.platform_assets);
        console.log(response.data.data.platform_assets);
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };

    const fetchOrganizations = async () => {
      try {
        const response = await api2.post("/tenant/list");
        setOrganizations(response.data.data.tenants);
        console.log(response.data.data.tenants);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchAssets();
    fetchOrganizations();
  }, []);

  const handleRadioChange = (organizationId) => {
    setSelectedOrganization(organizationId);
  };

  const handleCheckboxChange = (itemId) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const addAssetsToOrganization = async () => {
    const payload = {
      platform_assets: checkedItems,
      slug: selectedOrganization,
      platform_experiences: [],
    };

    try {
      const response = await api2.post("/tenant/upsertPlatformResource", payload);

      if (response.data.message === "success") {
        toast.success("Assets successfully added to organization");
      }

      console.log(response.data);
      setCheckedItems([]);
      setSelectedOrganization(null);
    } catch (error) {
      console.error("Error adding assets to organization:", error);
    }
  };

  const reloadFromS3 = async () => {
    try {
      const response = await api2.post("/platformAsset/reloadFromS3");

      if (response.data.message === "Successfully reload") {
        toast.success("Assets successfully reload");
      }

      console.log(response.data);
    } catch (error) {
      console.error("Error reloading from S3:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row lg:flex:row p-8 mt-5  text-center text-white items-center justify-center lg:justify-between">
        <h1 className="text-3xl mb-4 lg:mb-0">Platform Assets</h1>
        <div className="flex flex-col md:flex-row lg:flex-row">
          <button
            className="bg-default-button mb-4 lg:mb-0 text-white py-2 px-4 rounded mr-4"
            onClick={addAssetsToOrganization}
          >
            Add Assets to Organization
          </button>
          <button className="bg-default-button text-white py-2 px-4 rounded mr-4" onClick={reloadFromS3}>
            Reload From S3
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row lg:flex-row text-white m-10 mb-20">
        <div className="w-3/6">
          <h2 className="text-xl  mb-4 text-logo-color">List Of Available Platform Assets</h2>
          <ul>
            {assets.map((item) => (
              <li key={item.uuid} className="flex w-100 items-center pl-8  mb-5 text-xl">
                <input
                  type="checkbox"
                  id={`item-${item.uuid}`}
                  checked={checkedItems.includes(item.uuid)}
                  onChange={() => handleCheckboxChange(item.uuid)}
                  className="h-6 w-6 mr-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor={`item-${item.uuid}`}>{item.name}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-3/6">
          <h2 className="text-xl mb-4 text-logo-color">Organization List</h2>
          <ul>
            {organizations.map((organization) => (
              <li key={organization.slug} className="flex items-center mb-2 text-white">
                <input
                  type="radio"
                  id={`organization-${organization.slug}`}
                  name="organization"
                  value={organization.slug}
                  checked={selectedOrganization === organization.slug}
                  onChange={() => handleRadioChange(organization.slug)}
                  className="h-6 w-6 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor={`organization-${organization.slug}`} className="text-white">
                  {organization.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default PlatformAssets;
