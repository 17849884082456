import React from 'react';
import { FaBell } from 'react-icons/fa';

const NotificationBell = ({ notificationCount }) => {
  return (
    <div className="flex items-center mr-5">
    <div className="flex items-center justify-center rounded-full bg-dim-grey w-10 h-10">
      <FaBell className="text-2xl text-black" />
    </div>
    <div className="rounded-full bg-badge-color w-6 h-6 text-white flex items-center justify-center ml-[-8px] -mt-2 -mr-2">
      {notificationCount}
    </div>
  </div>
  );
};

export default NotificationBell;
