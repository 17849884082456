import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { apiAxiosForLogin } from '../../api/axiosConfig';
import toast from 'react-hot-toast';

const GoogleLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    // You can handle additional logic after successful login here, if needed
  }, [navigate]);

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const authInstance = getAuth();

      const result = await signInWithPopup(authInstance, provider);

      const idToken = await result.user.getIdToken();
      const requestData = {
        id_tkn: idToken,
        firebase_notification_tkn: "firebase_notification_tkn",
      };

      const loginResponse = await apiAxiosForLogin.post('user/login', requestData);
      const message = loginResponse.data.message;

      if (message === "You have successfully logged in!") {
        const tokenDetails = loginResponse.data.data;
        const user = result.user;

        if (user) {
          localStorage.setItem("accessToken", tokenDetails.access_token);
          localStorage.setItem("loginUserName", loginResponse.data.data.profile.name);
          localStorage.setItem("fuidId", loginResponse.data.data.profile.fuid);

          toast.success(message);
          navigate('/dashboard/tenant');
        }
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError(error);
      // Handle errors or show a message to the user
    }
  };

  return (
    <div>
      {error && (
        <div>
          {/* Show error message or UI elements for handling the error */}
          <p>Error: {error.message}</p>
        </div>
      )}
      <FcGoogle size={30} className='ml-4' onClick={handleGoogleLogin} />
    </div>
  );
};

export default GoogleLogin;
