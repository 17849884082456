import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import ReusableInputField from "../../Util/ReusableInputField";
import { api2 } from "../../../api/axiosConfig";
import emailValidator from 'email-validator';
function SignupFormWithOrganizationDetail(props) {
   const navigate=useNavigate();
  const [formData, setFormData] = useState({
    slug: "",
    owner_email: "",
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
  });
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: {
        ...prevFormData.address,
        [name]: value,
      },
    }));
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const nameRegex = /^[A-Za-z\s]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    const allowedSpecialCharactersRegex = /^[a-zA-Z0-9\s/-]*$/;
    const indiaPinCodeRegex = /^\d{6}$/;

    if (!formData.name?.trim() || !nameRegex.test(formData.name)) {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (!formData.slug?.trim() || !nameRegex.test(formData.slug)) {
      newErrors.slug = "Slug is required";
      valid = false;
    }

    if (!emailValidator.validate(formData.owner_email)) {
      newErrors.owner_email = 'Owner Email is required';
      valid = false;
    }

   
    for (const [field, regex] of Object.entries({
      line1: allowedSpecialCharactersRegex,
      line2: allowedSpecialCharactersRegex,
      city: alphanumericRegex,
      state: alphanumericRegex,
      zip_code: indiaPinCodeRegex,
      country: alphanumericRegex,
    })) {
      if (
        !formData.address[field]?.trim() ||
        !regex.test(formData.address[field])
      ) {
        newErrors[field] = `${field} is required`;
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };
  const CreateOrganization = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await  api2.post(`/tenant/create`, formData)
        console.log("Organization created successfully:", response.data);
        navigate('/signup-add-organization-theme');
      } catch (error) {
        console.error("Error Creating organization:", error);
      }
    } else {
      console.log("Form validation failed");
    }
  };



  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="max-w-md mx-auto mt-10 p-6">
          <h2 className="text-white font-bold text-xl lg:text-2xl">
            Let us complete creating your organization
          </h2>
          <form>
            <ReusableInputField 
            label="ORGANIZATION NAME"
             id="name"
             placeholder="Enter Organization name"
             value={formData.name}
             onChange={(e) => handleInputChange(e)} />
               {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            <ReusableInputField 
            label="SLUG" 
            id="slug"
            placeholder="Enter Slug"
            value={formData.slug}
            onChange={(e) => handleInputChange(e)} />
              {errors.slug && <p style={{ color: "red" }}>{errors.slug}</p>}
            <ReusableInputField 
            label="OWNER EMAIL" 
            id="owner_email" 
            placeholder="Enter Owner Email"
            value={formData.owner_email}
            onChange={(e) => handleInputChange(e)} 
            />
              {errors.owner_email && <p style={{ color: "red" }}>{errors.owner_email}</p>}
            <div className="text-white flex flex-row items-start justify-start mt-4">
              <p>ORGANIZATION ADDRESS</p>
            </div>

            <ReusableInputField label="ADDRESS LINE 1" id="line1" 
            placeholder="Enter Address Line 1"
            value={formData.address.line1}
            onChange={(e) => handleAddressInputChange(e)}  
            />
             {errors.line1 && <p style={{ color: "red" }}>{errors.line1}</p>}
            <ReusableInputField label="ADDRESS LINE 2" id="line2" 
            placeholder="Enter Address Line 2"
            value={formData.address.line2}
            onChange={(e) => handleAddressInputChange(e)}  
            />
             {errors.line2 && <p style={{ color: "red" }}>{errors.line2}</p>}
            <div className="flex flex-row items-start justify-start mt-4">
            <ReusableInputField label="ZIP CODE" id="zip_code" 
            placeholder="Enter Zip Code" 
            value={formData.address.zip_code}
            onChange={(e) => handleAddressInputChange(e)}  
            />
           
            <div className="ml-4">
            <ReusableInputField label="COUNTRY" id="country" 
            placeholder="Enter Country" 
            value={formData.address.country}
            onChange={(e) => handleAddressInputChange(e)}  
            />
            </div>
            
            </div>
            <div className="flex text-error-color">
            {errors.zip_code && <p className="mr-20">{errors.zip_code}</p>}
            {errors.country && <p>{errors.country}</p>}
            </div>
            <div className="flex flex-row items-start justify-start mt-4">
              <ReusableInputField label="STATE" id="state" 
              placeholder="Enter State"
              value={formData.address.state}
             onChange={(e) => handleAddressInputChange(e)}  
              />
               
              <div className="ml-4">
              <ReusableInputField label="CITY" id="city" 
              placeholder="Enter City"
              value={formData.address.city}
            onChange={(e) => handleAddressInputChange(e)}  
              />
              
              </div>
             
            </div>
            <div className="flex text-error-color">
            {errors.state && <p className="mr-20">{errors.state}</p>}
            {errors.city && <p>{errors.city}</p>}
            </div>
           
          

            <div className="flex mt-4">
              <Link to="/dashboard/tenant" className="mr-4">
                <button className="bg-grey-button w-40 text-white p-2 rounded-md button-sm" type="submit">
                  Back
                </button>
              </Link>
              
                <button className="bg-default-button text-white p-2 rounded-md w-40 button-sm" onClick={CreateOrganization}>
                  Next
                </button>
            
            </div>

            <div className="flex items-center justify-center mt-4">
              <p id="stellar_copyright" className="text-white absolute bottom-0">
                © 2023 ViXR Inc.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupFormWithOrganizationDetail;
