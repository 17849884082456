// src/components/ForgotPassword.js
import React, { useState } from 'react';
import { auth } from '../../firebase/firebase';
import {sendPasswordResetEmail} from "firebase/auth";
import toast from 'react-hot-toast';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth,email);
      setMessage('Password reset email sent. Check your inbox.');
      toast.success(message);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
      <div className="flex items-center justify-center h-screen">
    <div className="max-w-md mx-auto mt-8 p-6 ">
        <h2 className='text-white font-bold ' 
         style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
        >Forgot Password</h2>
        <br/><br/>
     
        <div className="mb-4">
          <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
            Email
          </label>
          <input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)}  className="w-full p-2 text-login-label border-b-[2px] bg-transparent" />
        </div>
        <br/>
        <button className="bg-default-button text-white p-2 rounded-md w-full button-sm" onClick={handleResetPassword}>Reset Password</button>
     <p id="stellar_copyright"className='text-white  absolute bottom-0 '>© 2023 ViXR Inc.</p>
    
    </div>
    </div>
  );
};

export default ForgotPassword;
