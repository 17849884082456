import { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const selectMenuItem = (item) => {
    setSelectedMenuItem(item);
  };

  const contextValue = useMemo(() => ({
    isMenuOpen,
    toggleMenu,
    selectedMenuItem,
    selectMenuItem
  }), [isMenuOpen, selectedMenuItem]);

  return (
    <MenuContext.Provider value={contextValue}>
      {children}
    </MenuContext.Provider>
  );
};

const MenuContext = createContext();

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMenuContext = () => {
  return useContext(MenuContext);
};
