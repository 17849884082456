import React from 'react';

function Contacts() {
    return (
        <div>
            Contacts
        </div>
    );
}

export default Contacts;