import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

const SearchBar = () => {
  const [value, setValue] = useState(null);
  const options = ['Everything','Option 1', 'Option 2'];

  return (
    <div className="flex items-center rounded-full text-white overflow-hidden bg-search-bar p-2">
      {/* Left Dropdown */}
      <select
        value={value}
        onChange={(event) => setValue(event.target.value)}
        className="text-cyan bg-search-bar  py-1 px-2 rounded-l focus:outline-none focus:border-none"
      >
        {options.map((option) => (
          <option key={option} value={option} className="text-gray-800">{option}</option>
        ))}
      </select>
      
      {/* Middle Search Text */}
      <input
        type="text"
        placeholder="Search..."
        className="flex-grow px-2 py-1 bg-search-bar text-white focus:outline-none focus:border-none focus:ring focus:ring-cyan"
      />
      
      {/* Right Search Icon */}
      <button type="button" className="text-white text-search-icon p-2 rounded-r">
        <AiOutlineSearch />
      </button>
    </div>
  );
};

export default SearchBar;
