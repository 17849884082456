import React, { useEffect, useState } from 'react';
import {api2} from '../../api/axiosConfig';
import { useParams } from 'react-router-dom';
import InputWithLabel from '../Util/InputWithLabel';

function OrganizationContstraints(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const params=useParams();
  useEffect(() => {
    // Define the specific endpoint for this component
    const endpoint = `/tenant/getConstraints`;
   
    // Use the configured Axios instance to make the API call
   const payload={"slug":params.slug}
    api2.post(endpoint,payload)
      .then(response => {
        setData(response.data.data.constraints);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
    return (
        <div>
            <InputWithLabel label="Organization Members" data={data.tenant_member}/>
            <InputWithLabel label="Organization Admin" data={data.tenant_admin}/>
            <InputWithLabel label="Organization Department" data={data.tenant_team}/>
            <InputWithLabel label="Organization Memory Size" data={data.tenant_memory_size}/>
            <InputWithLabel label="Organization Meetings" data={data.tenant_meeting}/>
            <InputWithLabel label="Organization Meeting Member" data={data.tenant_meeting_member}/>
            <InputWithLabel label="Organization Meeting Admin" data={data.tenant_meeting_admin}/>
            <InputWithLabel label="Organization Meeting Duration" data={data.tenant_meeting_duration}/>
            <InputWithLabel label="Organization Meeting Experience" data={data.tenant_meeting_experience}/>
            <InputWithLabel label="Organization Experience" data={data.tenant_experience}/>
            <InputWithLabel label="Organization Asset" data={data.tenant_asset}/>
            <InputWithLabel label="Organization Asset Memory Size" data={data.tenant_asset_memory_size}/>
            <InputWithLabel label="Organization Experience Asset" data={data.tenant_experience_asset}/>
            <InputWithLabel label="Department member" data={data.team_member}/>
            <InputWithLabel label="Department Admin" data={data.team_admin}/>
            <InputWithLabel label="Department meeting" data={data.team_meeting}/>
            <InputWithLabel label="Department Memory Size" data={data.team_memory_size}/>
            <InputWithLabel label="Department Experience" data={data.team_experience}/>
            <InputWithLabel label="Department Asset" data={data.team_assets}/>
          
        </div>
    );
}

export default OrganizationContstraints;