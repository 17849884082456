import React from 'react';
import LoginLeftPanel from '../components/Login/LoginLeftPanel';
import LoginRightPanel from '../components/Login/LoginRightPanel';

const LoginPage=()=>{
    return(
        
        <div className="flex flex-col md:flex-row h-screen">
        <LoginLeftPanel/>
        <LoginRightPanel/>
     </div>
    )
}
export default LoginPage