import MaterialTable from "@material-table/core";
import React from "react";
import tableIcons from "../../components/tableIcons";
import "./CustomTable.css";

const CustomTable = ({
  columnsData,
  tableData,
  actionData1,
  actionData2,
  actionData3,
  actionData4,
  actionData5,
  actionDataLabel1,
  actionDataLabel2,
  actionDataLabel3,
  actionDataLabel4,
  actionDataLabel5,
}) => {
  return (
    <>
      {actionData1 ||
      actionData2 ||
      actionData3 ||
      actionData4 ||
      actionData5 ? (
        <MaterialTable
          className="table"
          style={{ backgroundColor: "#141d29", color: "#ffffff" }}
          icons={tableIcons}
          columns={columnsData}
          data={tableData}
          options={{
            actionsCellStyle: {
              color: "#3AE4D6",
              border: "none",
              width: "27px",
            },
            cellStyle: {
              font: "inter",
              width: "50px",
              border: "none",
              fontSize: "12px",
            },
            headerStyle: {
              fontFamily: "Manrope",
              fontSize: "10px",
              fontWeight: 200,
              width: "27px",
              color: "#B2B6BE",
              border: "none",
            },
            search: false,
            showTitle: false,
            sorting: true,
            actionsColumnIndex: -1,
            showTextRowsSelected: false,
            toolbar: false,
            padding: "dense",
          }}
          actions={[
            actionData1
              ? {
                  // tooltip: actionDataLabel1,
                  onClick: (event, rowData) =>
                    actionData1(rowData.uuid ? rowData.uuid : rowData.id),

                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#3AE4D6",
                          width: "57px",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        {actionDataLabel1}
                      </span>{" "}
                    </div>
                  ),

                  "arial-lable": "",
                }
              : "",
            actionData2
              ? {
                  // tooltip: actionDataLabel2,
                  onClick: (event, rowData) =>
                    actionData2(rowData.uuid ? rowData.uuid : rowData.id),

                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#3AE4D6",
                          width: "57px",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        {actionDataLabel2}
                      </span>{" "}
                    </div>
                  ),

                  "arial-lable": "",
                }
              : "",
            actionData3
              ? {
                  // tooltip: actionDataLabel3,
                  onClick: (event, rowData) =>
                    actionData3(rowData.uuid ? rowData.uuid : rowData.id),

                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#3AE4D6",
                          width: "57px",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        {actionDataLabel3}
                      </span>{" "}
                    </div>
                  ),

                  "arial-lable": "",
                }
              : "",
            actionData4
              ? {
                  // tooltip: actionDataLabel4,
                  onClick: (event, rowData) =>
                    actionData4(rowData.uuid ? rowData.uuid : rowData.id),

                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#3AE4D6",
                          width: "57px",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        {actionDataLabel4}
                      </span>{" "}
                    </div>
                  ),

                  "arial-lable": "",
                }
              : "",
            actionData5
              ? {
                  // tooltip: actionDataLabel5,
                  onClick: (event, rowData) =>
                    actionData5(rowData.id, rowData.email),

                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#3AE4D6",
                          width: "57px",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        {actionDataLabel5}
                      </span>{" "}
                    </div>
                  ),

                  "arial-lable": "",
                }
              : "",
          ]}
        />
      ) : (
        <MaterialTable
          className="table"
          style={{ backgroundColor: "#141d29", color: "#ffffff" }}
          icons={tableIcons}
          columns={columnsData}
          data={tableData}
          options={{
            actionsCellStyle: {
              color: "#3AE4D6",
              border: "none",
              width: "27px",
            },
            cellStyle: {
              font: "inter",
              width: "50px",
              border: "none",
              fontSize: "12px",
            },
            headerStyle: {
              fontFamily: "Manrope",
              fontSize: "10px",
              fontWeight: 200,
              width: "27px",
              color: "#B2B6BE",
              border: "none",
            },
            search: false,
            showTitle: false,
            sorting: true,
            actionsColumnIndex: -1,
            showTextRowsSelected: false,
            toolbar: false,
            padding: "dense",
          }}
        />
      )}
    </>
  );
};

export { CustomTable };
