import React from 'react';
import { Link } from 'react-router-dom';

function SignupFormFinishedMessage(props) {
    return (
        <div>
        <div className="flex flex-col items-center justify-center text-center h-screen text-white font-bold ">
   
        <h2 className='' 
        style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
        >FINISHED!</h2>
        <br/>
        <div style={{ fontFamily: 'Manrope', fontSize: '24px', fontWeight: '200px' }}>
        <p>Congratulations!</p>
        <br/>
        <p>Organisation Setup is completed!</p>
        <p>You can start using Vixr</p>
       
        <br/>
        </div>
        <Link to="/dashboard/tenant"><button className='bg-default-button  text-white h-10 py-2 px-6 rounded '>Go</button></Link>
        <p id="stellar_copyright" className='text-white  absolute bottom-0 '>© 2023 ViXR Inc.</p>
        </div>
    </div>
    );
}

export default SignupFormFinishedMessage;