import React from "react";
import DashboardLeftPanel from "../components/Dashboard/DashboardLeftPanel";
import DashboardRightPanel from "../components/Dashboard/DashboardRightPanel";
import { MenuProvider } from '../context/MenuContext';
const DashboardPage = () => {
  return (
    <MenuProvider>
    <div className="flex flex-col md:flex-row h-screen">
      <DashboardLeftPanel />
      <DashboardRightPanel />
    </div>
    </MenuProvider>
  );
};

export default DashboardPage;
