import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useMenuContext } from '../../context/MenuContext';
import { IoMdBriefcase } from "react-icons/io";
import { MdStorage,MdAnalytics} from "react-icons/md";
import { TfiMoreAlt } from "react-icons/tfi";
import { FaUser, FaRegBuilding, FaDatabase } from "react-icons/fa";
import SearchBar from "../Util/SearchBar";
import NotificationBell from "../Util/NotificationBell";
import Profile from "../Util/Profile";
function DashboardRightPanel() {
  const { isMenuOpen } = useMenuContext();
  const location = useLocation();
  const matchpath=location.pathname.split('/').filter(Boolean).pop();
  return (
    <>
      <h1 className="md:hidden text-logo-color ml-2"  style={{ fontWeight: '300px', fontSize: '36px' }}>ViXR</h1>
     <div className={`overflow-auto m-0  bg-right-panel  ${isMenuOpen ? 'w-full' : 'w-full lg:w-10/12 transition-all duration-300 ease-out'}`}>
     <div className="flex flex-row justify-between  bg-header-bar text-white p-2">
       <div></div>
       <SearchBar/>
       <div className="flex flex-row  pl-20 pr-5">
       <NotificationBell notificationCount={6} />
      <Profile userName={'Indu Kushwaha'}/>
       </div>
      </div>
      <Outlet />
    </div>
      {/* Navigation tabs at the bottom (visible on mobile) */}
  <div className="lg:hidden bg-gray-200 p-4 fixed bottom-0 left-0 w-full flex justify-around ">
    {/* Replace these with your actual navigation tab components */}
    <Link to="analytics"><div className="text-center"> <MdAnalytics  size={30}  className={`mr-4 ${matchpath === 'analytics' ? 'text-default-button' : ''}` }/></div></Link>
    <Link to="tenant"><div className="text-center"> <FaRegBuilding size={30}  className={`mr-4 ${matchpath === 'tenant' ? 'text-default-button' : ''}` }/></div></Link>
    <Link to="platform-asset"><div className="text-center"><MdStorage size={30}  className={`mr-4 ${matchpath === 'platform-asset' ? 'text-default-button' : ''}` }/></div></Link>
    <Link to="platform-experience"><div className="text-center"> <IoMdBriefcase size={30}  className={`mr-4 ${matchpath === 'platform-experience' ? 'text-default-button' : ''}` } /></div></Link>
    <Link to="mongoData"><div className="text-center"><FaDatabase size={30}  className={`mr-4 ${matchpath === 'mongoData' ? 'text-default-button' : ''}` }/></div></Link>
    <Link to="user-profile"><div className="text-center"> <FaUser size={30}  className={`mr-4 ${matchpath === 'user-profile' ? 'text-default-button' : ''}` } /></div></Link>
    <Link to="more-content"><div className="text-center"><TfiMoreAlt size={30} className={`mr-4 ${matchpath === 'more-content' ? 'text-default-button' : ''}` }/></div></Link>
  </div>
    </>
   
    
  );
}

export default DashboardRightPanel;
