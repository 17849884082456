import React, { useEffect, useState } from "react";
import { api2 } from "../api/axiosConfig";
import toast from "react-hot-toast";

function PlatformExperience() {
  const [experiences, setExperiences] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    // Fetch Experiences list
    api2
      .post("/tenant/listPlatformResource")
      .then((response) => {
        setExperiences(response.data.data.platform_experiences);
        console.log(experiences);
      })
      .catch((error) => {
        console.error("Error fetching Experiences:", error);
      });

    // Fetch organizations list
    api2
      .post("/tenant/list")
      .then((response) => {
        setOrganizations(response.data.data.tenants);
        console.log(organizations);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  }, []);

  const handleRadioChange = (organizationId) => {
    setSelectedOrganization(organizationId);
  };
  const handleCheckboxChange = (itemId) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const addExperiencesToOrganization = () => {
    const payload = {
      platform_assets: [],
      slug: selectedOrganization,
      platform_experiences: checkedItems,
    };
    api2
      .post("/tenant/upsertPlatformResource", payload)
      .then((response) => {
        if (response.data.message === "success") {
          toast.success("Experiences successfully added to organization");
        }
        console.log(response.data);
        setCheckedItems([]);
        setSelectedOrganization(null);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  };
  const reloadFromS3 = () => {
    api2
      .post("/platformExperience/reloadFromS3")
      .then((response) => {
        if (response.data.message === "Successfully reload") {
          toast.success("Experiences successfully reload");
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row md:flex-row p-8 mt-5  text-center text-white itms-center justify-between">
        <h1 className="text-3xl mb-4 lg:mb-0">Platform Experiences</h1>
        <div className="flex flex-col lg:flex-row md:flex-row">
          <button
            className="bg-default-button mb-4 lg:mb-0 text-white py-2 px-4 rounded mr-4"
            onClick={addExperiencesToOrganization}
          >
            Add Experiences to Organization
          </button>
          <button
            className="bg-default-button  text-white py-2 px-4 rounded mr-4"
            onClick={reloadFromS3}
          >
            Reload From S3
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row text-white mt-10 ml-10">
        <div className=" w-full md:w-3/6">
          <h2 className="text-xl  mb-4 text-logo-color">
            List Of Available Platform Experiences
          </h2>

          <ul>
            {experiences.map((item) => (
              <li key={item.uuid} className="flex  items-center  mb-4 text-xl">
                <input
                  type="checkbox"
                  id={`item-${item.uuid}`}
                  checked={checkedItems.includes(item.uuid)}
                  onChange={() => handleCheckboxChange(item.uuid)}
                  className="h-6 w-6 mr-2 md:mr-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor={`item-${item.uuid}`}>{item.name}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full mt-10 md:mt-0 md:w-3/6">
          <h2 className="text-xl mb-4 text-logo-color">Organization List</h2>
          <ul>
            {organizations.map((organization) => (
              <li
                key={organization.slug}
                className="flex items-center mb-2 text-white"
              >
                <input
                  type="radio"
                  id={`organization-${organization.slug}`}
                  name="organization"
                  value={organization.slug}
                  checked={selectedOrganization === organization.slug}
                  onChange={() => handleRadioChange(organization.slug)}
                  className="h-6 w-6 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor={`organization-${organization.slug}`}
                  className="text-white"
                >
                  {organization.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default PlatformExperience;
