import React from 'react';

function OrganizationTheme() {
    return (
        <div>
           <div className="flex flex-row items-start justify-start  p-4  mt-8">
           <p className='text-grey-label p-8'>
                Logo
              </p>

              <div className=" ml-4">
              <div htmlFor="fileInput" className="inline-block  border-grey-button border-[1px]  p-10 rounded-xl cursor-pointer">
              <span className='underline text-grey-button'>Browse Here</span>
      <input
        type="file"
        id="fileInput"
        className="hidden"
        
      />
      </div>
              </div>
            </div>
            <p className='text-grey-label p-8'>Theme Colour</p>
            <div className='flex p-4'>
            <div className="flex flex-row items-start justify-start    mt-8">
              <div className=" ml-4">
                <p className='text-left text-sm text-grey-button'>Primary Colour</p>
                <input
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_name"
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start    mt-8">
              <div className=" ml-4">
                <p className='text-left text-sm text-grey-button'>Secondary Colour</p>
                <input
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_name"
                />
              </div>
            </div>
             </div>
        </div>
    );
}

export default OrganizationTheme;