import React, { useState } from "react";
import { api2, setAccessToken } from "../api/axiosConfig";
import toast from "react-hot-toast";

const MongoDataManager = () => {
  const [selectedScript, setSelectedScript] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [directoryName, setDirectoryName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tenantSlug, setTenantSlug] = useState("");
  const [dbName, setDbName] = useState("");
  const [responseData, setResponseData] = useState();
  const [showResponse, setShowResponse] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    collection: "",
    directoryName: "",
    selectedDate: "",
    tenantSlug: "",
    dbName: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);

  function createScriptObject(value, label, inputDescription, isFileUpload) {
    return {
      value,
      label,
      inputDescription,
      isFileUpload,
    };
  }

  const scriptTypes = [
    createScriptObject("noScript", "Select Script Type", null),
    createScriptObject(
      "mongoExport.sh",
      "Export Single Collection",
      ["Collection Name"],
      false
    ),
    createScriptObject("mongoDump.sh", "Export Entire DB", null),
    createScriptObject(
      "mongoExportTenant.sh",
      "Export Based on Tenant Slug",
      ["Tenant Slug"],
      false
    ),
    createScriptObject(
      "mongoDownloadFile.sh",
      "Download File from Server",
      ["Date (YYYY-MM-DD)", "Collection Name"],
      false
    ),
    createScriptObject(
      "mongoDownloadFolder.sh",
      "Download Directory from Server",
      ["Date (YYYY-MM-DD)", "Directory Name"],
      false
    ),
    createScriptObject(
      "mongoImport.sh",
      "Import Single Collection",
      ["Date (YYYY-MM-DD)", "Collection Name"],
      false
    ),
    createScriptObject(
      "mongoRestore.sh",
      "Restore Whole DB",
      ["Date (YYYY-MM-DD)", "DB Name"],
      false
    ),
    createScriptObject(
      "mongoUploadFile.sh",
      "Upload File to Server",
      null,
      true
    ),
    createScriptObject(
      "mongoUploadFolder.sh",
      "Upload Directory to Server",
      null,
      true
    ),
  ];

  const collections = [
    "tenants",
    "teams",
    "projects",
    "users",
    "experiences",
    "assets",
    "asset_association",
    "meetings",
    "meterings",
    "linkToken",
    "activitylogs",
  ];

  function resetForm() {
    // Reset the form
    setSelectedScript("");
    setSelectedCollection("");
    setSelectedDate("");
    setTenantSlug("");
    setDbName("");
    setDirectoryName("");
    setErrorMessages({
      collection: "",
      directoryName: "",
      selectedDate: "",
      tenantSlug: "",
      dbName: "",
    });
    setUploadedFile(null);
  }
  const selectedScriptInfo = scriptTypes.find(
    (script) => script.value === selectedScript
  );

  // Destructuring assignment for better readability
  const { inputDescription, isFileUpload: isFileRequired } =
    selectedScriptInfo || {};

  const isDateRequired = inputDescription?.includes("Date (YYYY-MM-DD)");
  const isCollectionRequired = inputDescription?.includes("Collection Name");
  const isTenantSlugRequired = inputDescription?.includes("Tenant Slug");
  const isdbNameRequired = inputDescription?.includes("DB Name");
  const isDirectoryNameRequired = inputDescription?.includes("Directory Name");

  const executeBackendOperation = async (endpoint, payload, config = {}) => {
    try {
      // Validate input fields
      const validationErrors = validateInputs();
      if (validationErrors.length > 0) {
        console.log(errorMessages);
        validationErrors.forEach((error) => toast.error(error));
        return;
      }

      // Reset the form
      resetForm();

      // Handle backend submission with the payload
      setAccessToken(localStorage.getItem("accessToken"));
      const response = await api2.post(endpoint, payload, config);
      setResponseData(response.data);

      // Additional logic for handleDownload
      if (config.responseType === "arraybuffer") {
        const arrayBuffer = new Uint8Array(response.data).buffer;
        const blob = new Blob([arrayBuffer], { type: "application/zip" });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `${
          payload.collection || payload.directoryName
        }`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      // Display success toast
      let toastMessage;

      if (endpoint === "/dbDataETL/exportImport") {
        toastMessage = "Exported Successfully!!";
      } else if (endpoint === "/dbDataETL/download") {
        toastMessage = "Download Successful!!";
      } else {
        toastMessage = "Uploaded Successfully!!";
      }
      toast.success(toastMessage);

      // Show response if needed
      setShowResponse(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      scriptType: selectedScript,
      collection: selectedCollection,
      dbName: dbName,
      tenantSlug: tenantSlug,
      date: selectedDate,
      directoryName: directoryName,
    };

    await executeBackendOperation("/dbDataETL/exportImport", payload);
  };

  const handleDownload = async () => {
    const payload = {
      scriptType: selectedScript,
      collection: selectedCollection,
      date: selectedDate,
      directoryName: directoryName,
    };

    await executeBackendOperation("/dbDataETL/download", payload, {
      responseType: "arraybuffer",
    });
  };

  const handleUpload = async (e) => {
    console.log(isFileRequired, uploadedFile);

    const payload = new FormData();
    payload.append("scriptType", selectedScript);
    payload.append("file", uploadedFile);

    await executeBackendOperation("/dbDataETL/upload", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const validateInputs = () => {
    const requiredFields = [
      {
        condition: isCollectionRequired,
        value: selectedCollection,
        field: "collection",
        message: "Collection is required.",
      },
      {
        condition: isDirectoryNameRequired,
        value: directoryName,
        field: "directoryName",
        message: "Directory Name is required.",
      },
      {
        condition: isDateRequired,
        value: selectedDate,
        field: "selectedDate",
        message: "Date is required.",
      },
      {
        condition: isTenantSlugRequired,
        value: tenantSlug,
        field: "tenantSlug",
        message: "Tenant Slug is required.",
      },
      {
        condition: isdbNameRequired,
        value: dbName,
        field: "dbName",
        message: "DB Name is required.",
      },
      {
        condition: isFileRequired,
        value: uploadedFile,
        field: "file",
        message: "File is required for the Operation.",
      },
    ];

    const errors = [];

    requiredFields.forEach(({ condition, value, field, message }) => {
      if (condition && !value) {
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: message,
        }));
        errors.push(message);
      }
    });

    return errors;
  };

  function returnInputField(label, setState, type = "text") {
    return (
      <div>
        <label className="block mb-2 text-sm font-bold text-[#3FF3E5]">
          {label}
        </label>
        <input
          type={type}
          className="w-full px-4 py-2 mb-4 rounded-md bg-[#141D29] text-white"
          onChange={(e) => setState(e.target.value)}
        />
      </div>
    );
  }

  const getButtonTextAndHandler = () => {
    if (
      selectedScript === "mongoDownloadFile.sh" ||
      selectedScript === "mongoDownloadFolder.sh"
    ) {
      return { text: "Download", handler: handleDownload };
    } else if (
      selectedScript === "mongoUploadFile.sh" ||
      selectedScript === "mongoUploadFolder.sh"
    ) {
      return { text: "Upload", handler: handleUpload };
    } else {
      return { text: "Submit", handler: handleSubmit };
    }
  };
  const { text: buttonText, handler: buttonClickHandler } =
    getButtonTextAndHandler();

  return (
    <>
      <div className="p-4 max-w-md mx-auto mt-10 bg-[#263245] rounded-md shadow-md">
        <label className="block mb-2 text-sm font-bold text-[#3FF3E5]">
          Select Operation:{" "}
        </label>
        <select
          className="w-full px-4 py-2 mb-4 rounded-md bg-[#141D29] text-white"
          value={selectedScript}
          onChange={(e) => setSelectedScript(e.target.value)}
        >
          {scriptTypes.map((script) => (
            <option
              className="border-none rounded"
              key={script.value}
              value={script.value}
            >
              {script.label}
            </option>
          ))}
        </select>
      </div>

      {selectedScript && (
        <div className="p-4 max-w-md mx-auto mt-4 bg-[#263245] rounded-md">
          {isCollectionRequired && (
            <div>
              <label className="block mb-2 text-sm font-bold text-[#3FF3E5]">
                Select Collection:{" "}
              </label>
              <select
                className="w-full px-4 py-2 mb-4 rounded-md text-white bg-[#141D29]"
                onChange={(e) => setSelectedCollection(e.target.value)}
              >
                <option value="">Select Collection</option>
                {collections.map((collection) => (
                  <option key={collection} value={collection}>
                    {collection}
                  </option>
                ))}
              </select>
            </div>
          )}

          {isDirectoryNameRequired &&
            returnInputField("Enter Directory Name:", setDirectoryName)}

          {isdbNameRequired && returnInputField("Enter DB Name:", setDbName)}

          {isTenantSlugRequired &&
            returnInputField("Enter Tenant Slug:", setTenantSlug)}

          {isDateRequired &&
            returnInputField("Select Date:", setSelectedDate, "date")}

          {selectedScript && selectedScriptInfo?.isFileUpload && (
            <div className="p-2 max-w-md mx-auto mt-4 bg-[#263245] rounded-md">
              <div>
                <label className="block mb-2 text-sm font-bold text-[#3FF3E5]">
                  Upload File:
                </label>
                <input
                  type="file"
                  className="w-full px-4 py-2 mb-4 rounded-md bg-[#141D29] text-white"
                  accept=".zip" // Set the accepted file type(s)
                  onChange={(e) => {
                    setUploadedFile(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          )}

          <button
            className="px-4 py-2 bg-[#F46C41] text-white rounded-md hover:bg-[#ef8a6b]"
            onClick={buttonClickHandler}
          >
            {buttonText}
          </button>
        </div>
      )}

      {!(
        selectedScript === "mongoDownloadFile.sh" ||
        selectedScript === "mongoDownloadFolder.sh" ||
        selectedScript === "noScript"
      ) &&
        responseData &&
        showResponse && (
          <div className="p-4 max-w-6xl mx-auto mt-10 bg-[#263245] rounded-md shadow-md">
            <div>
              <p className="block mb-2 text-sm font-bold text-[#3FF3E5]">
                Progress:
              </p>
              <div className="w-full px-4 py-2 mb-4 rounded-md bg-[#141D29] text-white">
                {JSON.stringify(responseData.data)}
              </div>

              <p className="block mb-2 text-sm font-bold text-[#3FF3E5]">
                Status:
              </p>
              <div className="w-full px-4 py-2 mb-4 rounded-md bg-[#141D29] text-white">
                {JSON.stringify(responseData.message)}
              </div>
            </div>
            <button
              className="cursor-pointer py-2 px-3 rounded-md bg-[#f46c41] text-white"
              tabIndex="0" // Make the element focusable
              onClick={() => setShowResponse(false)}
              onKeyDown={(e) => {
                // Trigger the handler on "Enter" key press
                if (e.key === "Enter") {
                  setShowResponse(false);
                }
              }}
            >
              CLOSE
            </button>
          </div>
        )}
    </>
  );
};

export default MongoDataManager;
