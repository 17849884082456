import React from 'react';
import OrganizationDetail from '../components/Organization/OrganizationDetail';

function TenantDetailPage(props) {

    return (
        <>
        <div className='flex p-8 mt-5  text-center text-white itms-center justify-between'>
           <h1 className='text-3xl'>Organization</h1> 
         
        </div>
        <OrganizationDetail/>
       
        </>
    );
}

export default TenantDetailPage;