import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import LogoutModal from "../Logout/LogoutModel";
import { useNavigate } from "react-router-dom";
import { useMenuContext } from "../../context/MenuContext";
import { auth } from "../../firebase/firebase";
import { signOut } from "firebase/auth";
import toast from "react-hot-toast";
function DashboardLeftPanel() {
  const { isMenuOpen } = useMenuContext();
  const navigate = useNavigate();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("User logged out successfully");
      // You can also redirect to a different page after logout if needed.
    } catch (error) {
      toast.error("Error during logout:", error);
    }
    localStorage.removeItem("accessToken");
    localStorage.clear();
    navigate("/", { replace: true });

    // Close the modal
    setIsLogoutModalOpen(false);
  };
  const showLogout = () => {
    setIsLogoutModalOpen(true);
  };
  return (
    <div
      className={`hidden md:block m-0 w-1/6 bg-left-panel overflow-y-auto ${
        isMenuOpen
          ? " w-full md:w-min transition-all duration-300 ease-in"
          : "w-full md:w-2/12"
      }`}
    >
      <Sidebar showLogout={showLogout} />
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onLogout={handleLogout}
      />
    </div>
  );
}

export default DashboardLeftPanel;
