import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { CustomTable } from "../../components/CustomTable";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SyncIcon from "@mui/icons-material/Sync";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const analyticsAPIURL = "https://analytics.tstvixr.in";

const AnalyticsDashboard = () => {
  const [meetingsOverallCount, setMeetingsOverallCount] = useState(0);

  const dashboardTileItems = [
    {
      count: meetingsOverallCount,
      title: "MEETINGS",
    },
    {
      count: 19502,
      title: "MEMBERS",
    },
    {
      count: 1520,
      title: "EXPERIENCES",
    },
    {
      count: 1024,
      title: "PROJECTS",
    },
    {
      count: 112560,
      title: "ASSETS USAGE",
    },
  ];

  // Analytics Section - Start
  // Meetings Analytics - Start
  const monthsData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weeksData = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
  const daysData = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Generate numbers from 1 to 31 and append to the array
  const [meetingsChartData, setMeetingsChartData] = useState([]);
  const [meetingsDataLabel, setMeetingsDataLabel] = useState("");
  const [meetingsLabelData, setMeetingsLabelData] = useState([]);
  const [meetingsBarGraphStatus, setMeetingsBarGraphStatus] =
    useState("Monthly");

  const [meetingsChartDataTemp1, setMeetingsChartDataTemp1] = useState([]);
  const [meetingsChartDataTemp2, setMeetingsChartDataTemp2] = useState([]);
  const [meetingsChartDataTemp3, setMeetingsChartDataTemp3] = useState([]);
  const [meetingsChartDataTemp4, setMeetingsChartDataTemp4] = useState([]);
  const [meetingsTableDataTemp, setMeetingsTableDataTemp] = useState([]);

  const [meetingHeadLabelTemp1, setMeetingHeadLabelTemp1] = useState("");
  const [meetingHeadLabelTemp2, setMeetingHeadLabelTemp2] = useState("");
  const [meetingHeadLabelTemp3, setMeetingHeadLabelTemp3] = useState("");
  const [meetingHeadLabelTemp4, setMeetingHeadLabelTemp4] = useState("");
  const [meetingHeadLabelTemp5, setMeetingHeadLabelTemp5] = useState("");

  const [meetingsDataLabelTemp1, setMeetingsDataLabelTemp1] = useState("");
  const [meetingsDataLabelTemp2, setMeetingsDataLabelTemp2] = useState("");
  const [meetingsDataLabelTemp3, setMeetingsDataLabelTemp3] = useState("");
  const [meetingsDataLabelTemp4, setMeetingsDataLabelTemp4] = useState("");

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  const [meetingBackBtnStatus, setMeetingBackBtnStatus] = useState(0);

  const [meetingHeadLabel, setMeetingHeadLabel] = useState("");

  // const [ chartYear1, setChartYear1 ] = useState();

  const dayTimeRange = [
    "00:00 - 00:59",
    "01:00 - 01:59",
    "02:00 - 02:59",
    "03:00 - 03:59",
    "04:00 - 04:59",
    "05:00 - 05:59",
    "06:00 - 06:59",
    "07:00 - 07:59",
    "08:00 - 08:59",
    "09:00 - 09:59",
    "10:00 - 10:59",
    "11:00 - 11:59",
    "12:00 - 12:59",
    "13:00 - 13:59",
    "14:00 - 14:59",
    "15:00 - 15:59",
    "16:00 - 16:59",
    "17:00 - 17:59",
    "18:00 - 18:59",
    "19:00 - 19:59",
    "20:00 - 20:59",
    "21:00 - 21:59",
    "22:00 - 22:59",
    "23:00 - 23:59",
  ];

  const [meetingsChartStatus, setMeetingsChartStatus] = useState(true);
  const [meetingDetailsStatus, setMeetingDetailsStatus] = useState(false);

  const [meetingDetails, setMeetingDetails] = useState([]);

  const [week1, setWeek1] = useState([]);
  const [week2, setWeek2] = useState([]);
  const [week3, setWeek3] = useState([]);
  const [week4, setWeek4] = useState([]);
  const [week5, setWeek5] = useState([]);

  const [sundayData, setSundayData] = useState([]);
  const [mondayData, setMondayData] = useState([]);
  const [tuesdayData, setTuesdayData] = useState([]);
  const [wednesdayData, setWednesdayData] = useState([]);
  const [thursdayData, setThursdayData] = useState([]);
  const [fridayData, setFridayData] = useState([]);
  const [saturdayData, setSaturdayData] = useState([]);

  //Get Week No. By Month - Start
  const getWeekNumberFromNano = (nanoDate) => {
    // Convert nano date to milliseconds
    // const millisecondsDate = nanoDate / 1e6;
    const millisecondsDate = nanoDate;

    // Create a Date object
    const dateObject = new Date(millisecondsDate);

    // Get year, month, and day
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth() + 1; // Months are zero-based
    const day = dateObject.getUTCDate();

    // Get the week number
    const weekNumber = getWeekNumber(year, month, day);
    // console.log(weekNumber);
    return weekNumber;
  };

  const getWeekNumber = (year, month, day) => {
    const firstDayOfMonth = new Date(Date.UTC(year, month - 1, 1));
    const firstDayOfWeek = firstDayOfMonth.getUTCDay();
    const adjustedDay = day + firstDayOfWeek - 1;
    const weekNumber = Math.ceil(adjustedDay / 7);

    return weekNumber;
  };
  // Example usage with nano date
  // const nanoDate = 1702252800000;
  // const weekNumber = getWeekNumberFromNano(nanoDate);

  const getMeetingsWeeklyData = (month) => {
    // console.log("Parsed Month" + month);
    //Get Week of data
    // getWeekNumberFromNano(nanoDate)
    //meetingCreatedDataPre - year data

    const week1 = [];
    const week2 = [];
    const week3 = [];
    const week4 = [];
    const week5 = [];

    // console.log("//get Weekly Data - By Month");
    let monthFilter = meetingCreatedData.filter((item) => {
      // getWeekNumberFromNano(item.timestamp)
      // console.log(new Date(item.timestamp).getMonth())
      // console.log(monthsData[i])
      const itemMonth = new Date(item.timestamp).getMonth();
      // console.log("itemMonth");
      // console.log(itemMonth);
      return itemMonth === month;
    });

    const monthDataArr = monthFilter;
    // console.log("Month Data for: " + month);
    // console.log(monthDataArr);

    for (let i = 1; i <= 5; i++) {
      let weekFilter = monthDataArr.filter((item) => {
        // console.log(item.timestamp);
        const week = getWeekNumberFromNano(item.timestamp);
        // console.log(week);
        return week === i;
      });

      switch (i) {
        case 1:
          week1.push(weekFilter);
          break;
        case 2:
          week2.push(weekFilter);
          break;
        case 3:
          week3.push(weekFilter);
          break;
        case 4:
          week4.push(weekFilter);
          break;
        case 5:
          week5.push(weekFilter);
          break;
        default:
      }
    }

    // console.log("//Weeks Data");
    // console.log(week1);
    // console.log(week2);
    // console.log(week3);
    // console.log(week4);
    // console.log(week5);

    setWeek1(week1);
    setWeek2(week2);
    setWeek3(week3);
    setWeek4(week4);
    setWeek5(week5);

    const weeksDataCount = [];

    weeksDataCount.push(week1[0].length);
    weeksDataCount.push(week2[0].length);
    weeksDataCount.push(week3[0].length);
    weeksDataCount.push(week4[0].length);
    weeksDataCount.push(week5[0].length);
    setMeetingsChartData(weeksDataCount);
    setMeetingsChartDataTemp2(weeksDataCount);
  };
  //Get Week No. By Month - End
  const [hourlyDataArr, setHourlyDataArr] = useState([]);
  // console.log(hourlyDataArr);

  const getMeetingsDailyData = (index) => {
    // console.log("//Get Daily Data");
    const weekIndex = index + 1;
    // console.log("//week Index");
    // console.log(weekIndex);
    // console.log("Week: ");
    // console.log(weeksData[index]);
    let weekDataArr = [];
    let daysDataArr = [];

    // console.log("//Weeks Data");
    // console.log(week1);
    // console.log(week2);
    // console.log(week3);
    // console.log(week4);
    // console.log(week5);

    switch (weekIndex) {
      case 1:
        weekDataArr = week1;
        break;
      case 2:
        weekDataArr = week2;
        break;
      case 3:
        weekDataArr = week3;
        break;
      case 4:
        weekDataArr = week4;
        break;
      case 5:
        weekDataArr = week5;
        break;
      default:
        break;
    }

    // console.log("//weekDataArr");
    // console.log(weekDataArr);

    let Sun = [];
    let Mon = [];
    let Tue = [];
    let Wed = [];
    let Thur = [];
    let Fri = [];
    let Sat = [];

    for (let i = 0; i < daysData.length; i++) {
      let dayName = daysData[i];
      // console.log("//DayNameArr");
      // console.log(dayName);
      let dayFilter = weekDataArr[0].filter((item) => {
        // console.log("//DayName");
        // console.log(daysData[new Date(item.timestamp).getDay()]);
        return daysData[new Date(item.timestamp).getDay()] === dayName;
      });

      // console.log("dayFilter");
      // console.log(dayFilter);

      switch (dayName) {
        case "Sunday":
          Sun.push(dayFilter);
          break;
        case "Monday":
          Mon.push(dayFilter);
          break;
        case "Tuesday":
          Tue.push(dayFilter);
          break;
        case "Wednesday":
          Wed.push(dayFilter);
          break;
        case "Thursday":
          Thur.push(dayFilter);
          break;
        case "Friday":
          Fri.push(dayFilter);
          break;
        case "Saturday":
          Sat.push(dayFilter);
          break;
        default:
          break;
      }

      // console.log("//Daily Data Day Name Wise");
      // console.log(Sun[0]);
      // console.log(Mon[0]);
      // console.log(Tue[0]);
      // console.log(Wed[0]);
      // console.log(Thur[0]);
      // console.log(Fri[0]);
      // console.log(Sat[0]);
    }

    setSundayData(Sun[0]);
    setMondayData(Mon[0]);
    setTuesdayData(Tue[0]);
    setWednesdayData(Wed[0]);
    setThursdayData(Thur[0]);
    setFridayData(Fri[0]);
    setSaturdayData(Sat[0]);

    daysDataArr.push(Sun[0].length);
    daysDataArr.push(Mon[0].length);
    daysDataArr.push(Tue[0].length);
    daysDataArr.push(Wed[0].length);
    daysDataArr.push(Thur[0].length);
    daysDataArr.push(Fri[0].length);
    daysDataArr.push(Sat[0].length);

    // console.log("//setMeetingsChartData");
    // console.log(daysDataArr);
    setMeetingsChartData(daysDataArr);
    setMeetingsChartDataTemp3(daysDataArr);
  };

  const [meetingsTimelyData, setMeetingsTimelyData] = useState([]);

  const getMeetingsHourlyData = (dayData) => {
    const weekDay = daysData[dayData];
    // console.log("Day: " + weekDay);
    // console.log("getMeetingsHourlyData");
    let daysDataArr = [];
    switch (weekDay) {
      case "Sunday":
        daysDataArr = sundayData;
        break;
      case "Monday":
        daysDataArr = mondayData;
        break;
      case "Tuesday":
        daysDataArr = tuesdayData;
        break;
      case "Wednesday":
        daysDataArr = wednesdayData;
        break;
      case "Thursday":
        daysDataArr = thursdayData;
        break;
      case "Friday":
        daysDataArr = fridayData;
        break;
      case "Saturday":
        daysDataArr = saturdayData;
        break;

      default:
        break;
    }

    setHourlyDataArr(daysDataArr);
    // console.log("daysDataArr");
    // console.log(daysDataArr);

    let hourlyDataArrPre = [];
    let hourlyDataCountArr = [];

    for (let i = 0; i < 25; i++) {
      const filteredHoursData = daysDataArr.filter((item) => {
        const date = new Date(item.timestamp);
        const hour = date.getHours();
        // console.log(hour);
        return hour >= i && hour <= i;
      });
      hourlyDataArrPre.push(filteredHoursData);
      hourlyDataCountArr.push(filteredHoursData.length);
    }

    setMeetingsChartData(hourlyDataCountArr);
    setMeetingsChartDataTemp4(hourlyDataCountArr);
    setMeetingsTimelyData(hourlyDataArrPre);

    // console.log("hourlyDataArrPre");
    // console.log(hourlyDataArrPre);
  };

  const getMeetingStartTime = (meetingUUID) => {
    // console.log("// RoomCreatedArray");
    // console.log(meetingStartedData);
    const filteredData = meetingStartedData.filter((item) => {
      const uuid = item.data.req.uuid;
      return meetingUUID === uuid;
    });
    // console.log("//Filtered Data");
    // console.log(filteredData);
    if (filteredData.length === 0) {
      return "-";
    } else {
      const timestamp = filteredData[filteredData.length - 1].timestamp;
      // console.log("//Meeting Started Timestamp");
      // console.log(timestamp);
      return timestamp;
    }
  };

  const getMeetingEndTime = (meetingUUID) => {
    // console.log("// Meeting Ended Data Array");
    // console.log(meetingEndedData);
    const filteredData = meetingEndedData.filter((item) => {
      const uuid = item.data.req.uuid;
      return meetingUUID === uuid;
    });
    if (filteredData.length === 0) {
      return "-";
    } else {
      const timestamp = filteredData[filteredData.length - 1].timestamp;
      // console.log("//Meeting Ended Timestamp");
      // console.log(timestamp);
      return timestamp;
    }
  };

  const [meetingsTableData, setMeetingsTableData] = useState([]);

  const getMeetingDuration = (startDate, endDate) => {
    const startNano =
      startDate.getTime() * 1e6 + startDate.getMilliseconds() * 1e6;
    const endNano = endDate.getTime() * 1e6 + endDate.getMilliseconds() * 1e6;
    const nanoDuration = endNano - startNano;

    const seconds = Math.floor(nanoDuration / 1e9);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes % 60).padStart(2, "0");
    const formattedSeconds = String(seconds % 60).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const getMeetingsTableData = (hourIndex) => {
    const meetingsDataPre = [];
    // console.log("hourIndex: " + hourIndex);
    // console.log("//meetingsTimelyData");
    // console.log(meetingsTimelyData);
    meetingsDataPre.push(meetingsTimelyData[hourIndex]);
    // console.log("//meetingsDataPre");
    // console.log(meetingsDataPre);

    const meetingDataArrPost = meetingsDataPre[0];
    // console.log("meetingDataArrPost");
    // console.log(meetingDataArrPost);
    const meetingsDataTableArr = [];

    for (let i = 0; i < meetingDataArrPost.length; i++) {
      // console.log("// Meeting UUID");
      // console.log(meetingDataArrPost[i].data.meeting_uuid);

      const startTime = getMeetingStartTime(
        meetingDataArrPost[i].data.meeting_uuid
      );

      const endTime = getMeetingEndTime(
        meetingDataArrPost[i].data.meeting_uuid
      );

      // const startTime = 1702447441839;
      // const endTime = 1702463566616;

      let duration = "-";
      if (startTime === "-" || endTime === "-") {
        duration = "-";
      } else {
        duration = getMeetingDuration(new Date(startTime), new Date(endTime));
      }

      // const duration = getMeetingDuration(startTime, endTime);

      // console.log("//Meeting Data Arr");
      // console.log(meetingDataArrPost[i]);

      // console.log("// Meeting Start Time");
      // console.log(startTime);

      let meetingDetailsObj = {
        id: meetingDataArrPost[i].data.meeting_uuid,
        meetingTitle: meetingDataArrPost[i].data.req.name,
        hostId: meetingDataArrPost[i].data.user_id,
        hostName: meetingDataArrPost[i].data.req.hosts[0],
        projectId: meetingDataArrPost[i].data.req.project_id,
        projectName: meetingDataArrPost[i].data.req.project_id,
        organizationId: meetingDataArrPost[i].data.req.tenant_slug,
        organizationName: meetingDataArrPost[i].data.req.tenant_slug,
        startTime:
          startTime === "-" ? startTime : new Date(startTime).toISOString(),
        endTime: endTime === "-" ? endTime : new Date(endTime).toISOString(),
        duration: duration.toString(),
      };

      meetingsDataTableArr.push(meetingDetailsObj);
    }

    // console.log("//Table Data Final : meetingsDataTableArr");
    // console.log(meetingsDataTableArr);
    setMeetingsTableData(meetingsDataTableArr);
    setMeetingsTableDataTemp(meetingsDataTableArr);
  };

  const data1 = {
    labels: meetingsLabelData,
    datasets: [
      {
        label: meetingsDataLabel,
        backgroundColor: "#6290C8",
        borderColor: "#263245",
        data: meetingsChartData,
      },
    ],
  };

  const options1 = {
    color: "white",
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        // console.log("Initial Graph Element: ");
        // console.log(evt);
        // console.log(element[0]);
        // console.log("Inner Graph Element: ");
        // console.log(element[0].element);
        // console.log("Context of Inner Graph Element: ");
        // console.log(element[0].element.$context);
        // console.log("Clicked Data Index: ");
        // console.log(element[0].index);
        // console.log("Clicked Label: ");
        // console.log(monthsData[element[0].index]);
        // console.log("Clicked Data: ");
        // console.log(element[0].element.$context.raw);
        // you can also get dataset of your selected element
        // console.log(data.datasets[element[0]._datasetIndex])
        // console.log(monthsData[element[0].index]);
        if (meetingsBarGraphStatus === "Monthly") {
          setSelectedMonth(monthsData[element[0].index]);
          setMeetingsBarGraphStatus("Weekly");
          getMeetingsWeeklyData(element[0].index);
          graphChangeMethod(
            monthsData[element[0].index],
            selectedYear,
            monthsData[element[0].index]
          );
        } else if (meetingsBarGraphStatus === "Weekly") {
          setSelectedWeek(weeksData[element[0].index]);
          setMeetingsBarGraphStatus("Daily");
          getMeetingsDailyData(element[0].index);
          graphChangeMethod(
            weeksData[element[0].index],
            selectedYear,
            selectedMonth,
            weeksData[element[0].index]
          );
        } else if (meetingsBarGraphStatus === "Daily") {
          setSelectedDay(daysData[element[0].index]);
          setMeetingsBarGraphStatus("Timely");
          getMeetingsHourlyData(element[0].index);
          graphChangeMethod(
            daysData[element[0].index],
            selectedYear,
            selectedMonth,
            selectedWeek,
            daysData[element[0].index]
          );
        } else if (meetingsBarGraphStatus === "Timely") {
          setMeetingsBarGraphStatus("Table");
          getMeetingsTableData(element[0].index);
          graphChangeMethod(
            dayTimeRange[element[0].index],
            selectedYear,
            selectedMonth,
            selectedWeek,
            daysData[element[0].index]
          );
        }
      }
    },
  };

  const [meetingCreatedData, setMeetingCreatedData] = useState([]);

  const currentDateStr = new Date();
  const year = currentDateStr.getFullYear();

  // Create a new Date object for the start of the current year
  const yearStartDate = new Date(year, 0, 1);

  // Adjust for the local time zone offset
  const timezoneOffset = yearStartDate.getTimezoneOffset();
  yearStartDate.setMinutes(yearStartDate.getMinutes() - timezoneOffset);

  // Get the ISO string for the start date
  const isoStartDate = yearStartDate.toISOString();

  const [startDate, setStartDate] = useState(isoStartDate);
  const [endDate, setEndDate] = useState(currentDateStr.toISOString());

  const initialYear = 2023;

  const [chartYears, setChartYears] = useState([]);

  //getCreateMeetingsData
  const getCreateMeetingsData = async () => {
    const data = {
      // tenant_slug: "archer",
      start: startDate,
      end: endDate,
      scope: "wonder",
      action: "create",
      limit: 1000,
    };

    await axios
      .post(`${analyticsAPIURL}/meeting/list`, data)
      .then((response) => {
        // console.log("//API Analytics Data Call");
        // console.log(response.data.response);
        // console.log(JSON.parse((response.data.response)));

        let meetingCreateDataArr = response.data.response;
        let meetingCreatedDataPre = [];

        setMeetingsOverallCount(meetingCreateDataArr.length);

        for (let i = 0; i < meetingCreateDataArr.length; i++) {
          let dateStr = meetingCreateDataArr[i][0].slice(0, 13);
          // console.log(dateStr)
          let fullDateOrg = new Date(parseInt(dateStr));
          // console.log(fullDateOrg)
          let dateMonthInt = fullDateOrg.getMonth();
          let dateMonthStr = monthsData[new Date(parseInt(dateStr)).getMonth()];
          // console.log(monthsData[dateMonthInt]);
          // console.log(dateMonthStr);
          // console.log(JSON.parse((meetingCreateDataArr[i])[1]))
          let responseData = JSON.parse(meetingCreateDataArr[i][1]);

          meetingCreatedDataPre.push({
            timestamp: parseInt(dateStr),
            data: responseData,
          });
        }

        setMeetingCreatedData(meetingCreatedDataPre);
        // console.log("//Meetings Created Data");
        // console.log(meetingCreatedDataPre);

        const yearMeetingsData = [];

        for (let i = 0; i < monthsData.length; i++) {
          let filteredCreatedMeetingData = meetingCreatedDataPre.filter(
            (item) => {
              // console.log(new Date(item.timestamp).getMonth())
              // console.log(monthsData[i])
              return new Date(item.timestamp).getMonth() === i;
            }
          );

          // if(filteredCreatedMeetingData.length == 0){
          //   yearMeetingsData.push(Math.floor(Math.random() * 50));
          // }else{
          //   yearMeetingsData.push(filteredCreatedMeetingData.length);
          // }

          yearMeetingsData.push(filteredCreatedMeetingData.length);

          // console.log("Length");
          // console.log(filteredCreatedMeetingData.length);
        }

        setMeetingsChartData(yearMeetingsData);
        setMeetingsChartDataTemp1(yearMeetingsData);
        // setMeetingCreatedData(meetingCreatedDataPre)

        // response.data.response.map((item, idx) => {
        //   meetingDataArr.push(JSON.parse(item[1]))
        //   console.log((item[1]))
        // })
      })
      .catch((error) => {
        console.log("//API Analytics Created Meetings Data - Error");
        console.log(error);
      });
  };

  const [meetingStartedData, setMeetingStartedData] = useState([]);

  //getStartMeetingsData
  const getStartMeetingsData = async () => {
    const data = {
      //tenant_slug: "archer",
      start: startDate,
      end: endDate,
      scope: "wonder",
      action: "roomCreated",
      limit: 1000,
    };

    await axios
      .post(`${analyticsAPIURL}/meeting/list`, data)
      .then((response) => {
        // console.log("//API Analytics Data Call");
        // console.log(response.data.response);
        // console.log(JSON.parse((response.data.response)));

        let meetingStartedDataArr = response.data.response;
        let meetingStartedDataPre = [];

        for (let i = 0; i < meetingStartedDataArr.length; i++) {
          let dateStr = meetingStartedDataArr[i][0].slice(0, 13);
          // console.log(dateStr)
          let fullDateOrg = new Date(parseInt(dateStr));
          // console.log(fullDateOrg)
          let dateMonthInt = fullDateOrg.getMonth();
          let dateMonthStr = monthsData[new Date(parseInt(dateStr)).getMonth()];
          // console.log(monthsData[dateMonthInt]);
          // console.log(dateMonthStr);
          // console.log(JSON.parse((meetingStartedDataArr[i])[1]))
          let responseData = JSON.parse(meetingStartedDataArr[i][1]);

          meetingStartedDataPre.push({
            timestamp: parseInt(dateStr),
            data: responseData,
          });
        }

        setMeetingStartedData(meetingStartedDataPre);
        // console.log("//meetingStartedDataPre");
        // console.log(meetingStartedDataPre);
      })
      .catch((error) => {
        console.log("//API Analytics Meeting Started Data - Error");
        console.log(error);
      });
  };

  const [meetingEndedData, setMeetingEndedData] = useState([]);

  //getEndMeetingsData
  const getEndMeetingsData = async () => {
    const data = {
      //tenant_slug: "archer",
      start: startDate,
      end: endDate,
      scope: "wonder",
      action: "end",
      limit: 1000,
    };

    await axios
      .post(`${analyticsAPIURL}/meeting/list`, data)
      .then((response) => {
        // console.log("//API Analytics Data Call");
        // console.log(response.data.response);
        // console.log(JSON.parse((response.data.response)));

        let meetingEndedDataArr = response.data.response;
        let meetingEndedDataPre = [];

        for (let i = 0; i < meetingEndedDataArr.length; i++) {
          let dateStr = meetingEndedDataArr[i][0].slice(0, 13);
          // console.log(dateStr)
          let fullDateOrg = new Date(parseInt(dateStr));
          // console.log(fullDateOrg)
          let dateMonthInt = fullDateOrg.getMonth();
          let dateMonthStr = monthsData[new Date(parseInt(dateStr)).getMonth()];
          // console.log(monthsData[dateMonthInt]);
          // console.log(dateMonthStr);
          // console.log(JSON.parse((meetingEndedDataArr[i])[1]))
          let responseData = JSON.parse(meetingEndedDataArr[i][1]);

          meetingEndedDataPre.push({
            timestamp: parseInt(dateStr),
            data: responseData,
          });
        }

        setMeetingEndedData(meetingEndedDataPre);
        // console.log("//meetingEndedDataPre");
        // console.log(meetingEndedDataPre);
      })
      .catch((error) => {
        console.log("//API Analytics Meetings Ended Data - Error");
        console.log(error);
      });
  };

  const calculateChartYears = () => {
    const currentYear = new Date().getFullYear();
    const chartYearsArr = [];

    const yearDiff = currentYear - initialYear;

    if (yearDiff === 0) {
      chartYearsArr.push(initialYear);
    } else {
      for (let i = initialYear; i <= currentYear; i++) {
        chartYearsArr.push(i);
      }
    }
    setChartYears(chartYearsArr);
  };

  useEffect(() => {
    calculateChartYears();

    // console.log("End Date");
    // console.log(endDate);
    // console.log("Start Date");
    // console.log(startDate);

    // console.log("//API Analytics Data");

    // console.log("//Get Meetings - API Call");
    // console.log("//Get Create Meetings - API Call");
    getCreateMeetingsData();
    // console.log("//Get Start Meetings - API Call");
    getStartMeetingsData();
    // console.log("//Get End Meetings - API Call");
    getEndMeetingsData();

    setMeetingHeadLabel(selectedYear);
    setMeetingHeadLabelTemp1(selectedYear);
    setMeetingsDataLabel("Meetings Report: " + selectedYear);
    setMeetingsDataLabelTemp1("Meetings Report: " + selectedYear);
    setMeetingsLabelData(monthsData);

    // console.log(monthsData);
    // console.log(weeksData);
    // console.log(daysData);
  }, []);

  //Meetings Refresh Button Copy of useEffect with Extras
  const refreshCharts = () => {
    setMeetingBackBtnStatus(0);

    setMeetingsBarGraphStatus("Monthly");
    setMeetingDetailsStatus(false);
    setMeetingsChartStatus(true);

    calculateChartYears();

    getCreateMeetingsData();
    getStartMeetingsData();
    getEndMeetingsData();

    setMeetingHeadLabel(selectedYear);
    setMeetingHeadLabelTemp1(selectedYear);
    setMeetingsDataLabel("Meetings Report: " + selectedYear);
    setMeetingsDataLabelTemp1("Meetings Report: " + selectedYear);

    setMeetingsLabelData(monthsData);
  };

  const graphChangeMethod = (data, year, month, week, day) => {
    // console.log(data);
    if (monthsData.includes(data)) {
      setMeetingBackBtnStatus(1);
      setMeetingHeadLabel(data + ", " + meetingHeadLabel);
      setMeetingHeadLabelTemp2(data + ", " + meetingHeadLabel);
      setMeetingsDataLabel(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsDataLabelTemp2(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsLabelData(weeksData);
    } else if (weeksData.includes(data)) {
      setMeetingBackBtnStatus(2);
      setMeetingHeadLabel(week + ", " + meetingHeadLabel);
      setMeetingHeadLabelTemp3(week + ", " + meetingHeadLabel);
      setMeetingsDataLabel(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsDataLabelTemp3(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsLabelData(daysData);
      // console.log("Calling This");
    } else if (daysData.includes(data)) {
      setMeetingBackBtnStatus(3);
      setMeetingHeadLabel(day + ", " + meetingHeadLabel);
      setMeetingHeadLabelTemp4(day + ", " + meetingHeadLabel);
      setMeetingsDataLabel(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsDataLabelTemp4(
        "Meetings Details: " + data + ", " + meetingHeadLabel
      );
      setMeetingsLabelData(dayTimeRange);
      // dayTimeRange
      // setMeetingsChartStatus(false)
    } else if (dayTimeRange.includes(data)) {
      setMeetingBackBtnStatus(4);
      setMeetingsChartStatus(false);
      setMeetingHeadLabel(data + ", " + meetingHeadLabel);
      setMeetingHeadLabelTemp5(data + ", " + meetingHeadLabel);
      // console.log("gets meetings in table");
      // dayTimeRange
      // setMeetingsChartStatus(false)
    }
  };

  // console.log("meetingsTableData");
  // console.log(meetingsTableData);

  const getMeetingDetailsData = (meetingUUID) => {
    const meetingDetailArr = meetingsTableData.filter((item) => {
      return item.id === meetingUUID;
    });

    // console.log("//getMeetingDetailsData");
    // console.log(meetingDetailArr);

    setMeetingDetails([
      {
        meetingId: meetingDetailArr[0].id,
        meetingTitle: meetingDetailArr[0].meetingTitle,
        startTime: meetingDetailArr[0].startTime,
        endTime: meetingDetailArr[0].endTime,
        hostId: meetingDetailArr[0].hostId,
        hostName: meetingDetailArr[0].hostName,
        projectId: meetingDetailArr[0].projectName,
        projectName: meetingDetailArr[0].projectName,
        organizationId: meetingDetailArr[0].organizationId,
        organizationName: meetingDetailArr[0].organizationName,
        experiences: [
          {
            id: 1,
            title: "Test Experience",
            startTime: "Nil",
            endTime: "Nil",
            duration: "Nil",
          },
        ],
        ownerId: "USERID1",
        ownerName: "UserName1",
        userAttended: [
          { userId: "USERID2", userName: "UserName2" },
          { userId: "USERID3", userName: "UserName3" },
          { userId: "USERID4", userName: "UserName4" },
          { userId: "USERID5", userName: "UserName5" },
          { userId: "USERID6", userName: "UserName6" },
        ],
      },
    ]);
  };

  const showMeetingDetails = (meetingUUID) => {
    setMeetingBackBtnStatus(5);
    getMeetingDetailsData(meetingUUID);
    // console.log("Meeting Id: " + meetingUUID);
    setMeetingDetailsStatus(true);

    // console.log("//Meeting 1 Details: ");
    // console.log(meetingDetails);
  };

  const meetingColumns = [
    // {
    //   title: "Meeting ID",
    //   field: "id",
    // },
    {
      title: "Meeting Title",
      field: "meetingTitle",
    },
    {
      title: "Start Time",
      field: "startTime",
    },
    {
      title: "End Time",
      field: "endTime",
    },

    {
      title: "Duration",
      field: "duration",
    },
  ];

  const meetingsBackBtnClickHandle = () => {
    let btnStatus = meetingBackBtnStatus - 1;
    setMeetingBackBtnStatus(btnStatus);
    // alert(btnStatus);
    // setMeetingHeadLabelTemp1, setMeetingsDataLabelTemp1;
    switch (btnStatus) {
      case 0:
        setMeetingHeadLabel(meetingHeadLabelTemp1);
        setMeetingsDataLabel(meetingsDataLabelTemp1);
        setMeetingsLabelData(monthsData);
        setMeetingsBarGraphStatus("Monthly");
        setMeetingsChartData(meetingsChartDataTemp1);
        break;
      case 1:
        setMeetingHeadLabel(meetingHeadLabelTemp2);
        setMeetingsDataLabel(meetingsDataLabelTemp2);
        setMeetingsLabelData(weeksData);
        setMeetingsBarGraphStatus("Weekly");
        setMeetingsChartData(meetingsChartDataTemp2);
        break;
      case 2:
        setMeetingHeadLabel(meetingHeadLabelTemp3);
        setMeetingsDataLabel(meetingsDataLabelTemp3);
        setMeetingsLabelData(daysData);
        setMeetingsBarGraphStatus("Daily");
        setMeetingsChartData(meetingsChartDataTemp3);
        break;
      case 3:
        setMeetingHeadLabel(meetingHeadLabelTemp4);
        setMeetingsDataLabel(meetingsDataLabelTemp4);
        setMeetingsChartStatus(true);
        setMeetingsLabelData(dayTimeRange);
        setMeetingsBarGraphStatus("Timely");
        setMeetingsChartData(meetingsChartDataTemp4);
        break;
      case 4:
        setMeetingHeadLabel(meetingHeadLabelTemp5);
        setMeetingDetailsStatus(false);
        setMeetingsTableData(meetingsTableDataTemp);
        break;

      default:
        break;
    }
  };

  const getFullTimeFromUTCDate = (UTCDate) => {
    const UTCHours = new Date(UTCDate).getHours();
    const UTCMinutes = new Date(UTCDate).getMinutes();
    const UTCSeconds = new Date(UTCDate).getSeconds();
    return `${UTCHours}:${UTCMinutes}:${UTCSeconds}`;
  };

  const getDateFromUTC = (UTCDate) => {
    const UTCYear = new Date(UTCDate).getUTCFullYear();
    const UTCMonth = new Date(UTCDate).getUTCMonth();
    const UTCDay = new Date(UTCDate).getUTCDate();
    return `${UTCDay}/${UTCMonth}/${UTCYear}`;
  };

  return (
    <>
      {/* <GraphQL /> */}

      {/* Analytics Section Start */}
      <div className="w-[100%] gap-5 flex flex-wrap justify-evenly my-10">
        {dashboardTileItems.map((item, idx) => (
          <div
            key={idx}
            className="w-[260px] bg-[#263245] text-[#3ff3e5] flex items-center justify-center rounded-[25px] h-[120px]"
          >
            <div className="text-center">
              <h1 className="font-black text-5xl mb-3">{item.count}</h1>
              <p className="font-black text-xl">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-between gap-3 px-5 items-center">
        <div className="text-[#3ff3e5] text-3xl font-bold">
          Anaytics Reports
        </div>
        <div className="flex justify-end gap-3 px-5 items-center">
          <div className="text-white">Report Year:</div>
          <div className="">
            <FormControl>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  borderRadius: "15px",
                  bgcolor: "#263245",
                  color: "#3ff3e5",
                  "& .MuiSelect-icon": {
                    color: "#3ff3e5",
                  },
                  "&:hover": {
                    "&& fieldset": {
                      border: "1px solid #263245",
                    },
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#263245",
                      "& .MuiButtonBase-root.MuiMenuItem-root": {
                        color: "#3ff3e5",
                        backgroundColor: "#263245",
                      },
                      "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
                        color: "#3ff3e5",
                        backgroundColor: "#141D29",
                      },
                      "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                        color: "#141D29",
                        backgroundColor: "#3ff3e5",
                      },
                      "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover":
                        {
                          color: "#3ff3e5",
                          backgroundColor: "#141D29",
                        },
                    },
                  },
                }}
              >
                {chartYears.map((item, idx) => (
                  <MenuItem key={idx} sx={{}} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <button
            onClick={() => refreshCharts()}
            className="bg-[#263245] px-3 py-3.5 rounded-xl"
          >
            <span className="text-regular text-[#3ff3e5]">
              <SyncIcon /> Sync Reports
            </span>
          </button>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 mb-20">
        <div className="p-4">
          <div className="mb-5 px-5 py-10 rounded-3xl bg-[#263245]">
            <div
              className={`text-center w-100 mb-4 flex ${
                meetingBackBtnStatus === 0
                  ? "justify-center"
                  : "justify-between items-center"
              }`}
            >
              <p className="text-[#3ff3e5] font-bold text-2xl">
                Meetings - Report
              </p>
              {meetingBackBtnStatus === 0 ? (
                ""
              ) : (
                <button
                  className="flex items-center bg-[#141D29] rounded-xl px-3 py-2 text-[#3ff3e5]"
                  onClick={() => meetingsBackBtnClickHandle()}
                >
                  <ArrowBackIosIcon fontSize="inherit" /> Back
                </button>
              )}
            </div>
            {meetingsChartStatus ? (
              <Bar options={options1} data={data1} />
            ) : (
              <>
                {meetingDetailsStatus ? (
                  <>
                    <h3 className="text-white text-xl font-bold text-center mb-5">
                      Meeting Details
                    </h3>
                    {meetingDetails.map((item, idx) => (
                      <div key={idx} className="px-5">
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                          <p className="text-white mb-5">
                            ID:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.meetingId}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Title:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.meetingTitle}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Host:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.hostName}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Project:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.projectName}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Organization:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.organizationName}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Experience Used:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.experiences.length}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Users Attended:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {item.userAttended.length}
                            </span>
                          </p>
                          <p className="text-white mb-5">
                            Duration:&nbsp;
                            <span className="text-[#3ff3e5]">
                              {getMeetingDuration(
                                new Date(item.startTime),
                                new Date(item.endTime)
                              ) === "NaN:NaN:NaN"
                                ? "Nil"
                                : getMeetingDuration(
                                    new Date(item.startTime),
                                    new Date(item.endTime)
                                  )}
                            </span>
                          </p>
                          <div>
                            <p className="text-white mb-5">
                              Date:&nbsp;
                              <span className="text-[#3ff3e5]">
                                {item.startTime === "-"
                                  ? "Nil"
                                  : getDateFromUTC(item.startTime)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-[#3ff3e5]">
                            Start Time:&nbsp;
                            {item.startTime === "-"
                              ? "Nil"
                              : getFullTimeFromUTCDate(item.startTime)}
                          </p>
                          <p className="text-[#3ff3e5]">
                            End Time:&nbsp;
                            {item.endTime === "-"
                              ? "Nil"
                              : getFullTimeFromUTCDate(item.endTime)}
                          </p>
                        </div>
                        <div className="bg-[#141D29] flex justify-evenly">
                          {item.experiences.map((data, idx) => (
                            <div
                              key={idx}
                              className="flex-column text-center py-2"
                            >
                              <div className="text-center">
                                <p className="text-[#3ff3e5]">
                                  {idx === 0
                                    ? item.startTime === "-"
                                      ? "Nil"
                                      : getFullTimeFromUTCDate(item.startTime)
                                    : getFullTimeFromUTCDate(data.startTime)}
                                  &nbsp;-&nbsp;{data.endTime}
                                </p>
                              </div>
                              <div className="bg-[#3ff3e5]">
                                <p className="text-[#141d29] font-bold px-5">
                                  {data.title}
                                </p>
                                <p className="text-[#141d29] font-bold px-5">
                                  {data.duration}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <label className="text-white">
                      {"Meetings Details: " + meetingHeadLabel}
                    </label>
                    <CustomTable
                      columnsData={meetingColumns}
                      tableData={meetingsTableData}
                      actionData1={showMeetingDetails}
                      actionDataLabel1={"View"}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {/* Charts - Add Here */}
      </div>
      {/* Analytics Section End */}
    </>
  );
};

export default AnalyticsDashboard;
