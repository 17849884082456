import React, { useState} from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import GoogleLogin from "./GoogleLogin";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "otp-input-react";
import { auth } from "../../firebase/firebase";
import {setAccessToken, apiAxiosForLogin} from '../../api/axiosConfig';
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import toast from "react-hot-toast";

const LoginForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSendOtpEnabled, setIsSendOtpEnabled] = useState(false);
  const [isVerifyButtonEnabled, setIsVerifyButtonEnabled] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState(null);

  // Empty dependency array ensures it runs only once on mount
  const sendOtp = async () => {
    try {
      const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

      const recaptchaVerifierRef = new RecaptchaVerifier(auth, "recaptcha", {});

      const confirmation = await signInWithPhoneNumber(
        auth,
        `+${cleanedPhoneNumber}`,
        recaptchaVerifierRef
      );
      setUser(confirmation);
      setIsVerifyButtonEnabled(true);
    } catch (err) {
      console.error(err);
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await user.confirm(otp);
      if (response) {
        const idToken = response._tokenResponse.idToken;
        const requestData = {
          id_tkn: idToken,
          firebase_notification_tkn: "firebase_notification_tkn",
        };

        const loginResponse = await apiAxiosForLogin.post(
          "user/login",
          requestData
        );
        const message = loginResponse.data.message;

        if (message === "You have successfully logged in!") {
          const tokenDetails = loginResponse.data.data;
          const auth = getAuth();
          const user = auth.currentUser;
          if (user !== null) {
            localStorage.setItem("accessToken", tokenDetails.access_token);
            setAccessToken(tokenDetails.access_token);
            localStorage.setItem(
              "loginUserName",
              loginResponse.data.data.profile.name
            );
            localStorage.setItem(
              "fuidId",
              loginResponse.data.data.profile.fuid
            );

            toast.success(message);
            navigate("/dashboard/tenant");
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePhoneNumberSubmit = (event) => {
    event.preventDefault();
    sendOtp();
    setIsVerifyButtonEnabled(true);
  };

  const handleOnChange = (value, country) => {
    console.log("Phone Number:", value);
    console.log("Country:", country);
    const cleanedValue = value.replace(/[ -]/g, ""); // Remove spaces and dashes
    setPhoneNumber(cleanedValue);

    // Example: Validate phone number with country code +91
    const phoneRegex = /^(\+\d{1,4})?(\d{10,15})$/;
    const isValidPhoneNumber = phoneRegex.test(cleanedValue);
    setIsSendOtpEnabled(isValidPhoneNumber);
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="max-w-lg mx-auto mt-8 p-6 ">
        <h2
          className="text-white font-bold "
          style={{
            fontFamily: "Manrope",
            fontSize: "36px",
            fontWeight: "600px",
          }}
        >
          Login to ViXR
        </h2>
        <br />
        <br />
        <form onSubmit={handlePhoneNumberSubmit}>
          {/* Phone Number Input */}
          <div className="mb-4">
            <label
              className="block text-login-label text-sm font-bold mb-2"
              htmlFor="phoneNumber"
            >
              Enter Phone Number
            </label>
            <PhoneInput
              country={"in"} // Default country
              value={phoneNumber}
              onChange={handleOnChange}
              inputStyle={{ width: "100%" }} // Optional: Adjust the input width
            />
            <br />
            <div id="recaptcha"></div>
          </div>
          <p className="text-login-label text-sm mb-4">
            By proceeding you confirm to Agree to ViXR Terms of Use
          </p>

          {/* Get OTP Button */}
          {!isVerifyButtonEnabled ? (
            <div>
              <button
                disabled={!isSendOtpEnabled}
                className={`${isSendOtpEnabled ? 'bg-default-button hover:font-bold' : 'bg-disabled-button'} text-white p-2 rounded-md w-full button-sm`}
                onClick={sendOtp}
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                OtpType="number"
                autofocus
                className="otp-container text-bg-black"
              ></OtpInput>{" "}
              <br />
              <button
                className="bg-default-button text-white p-2 rounded-md w-full button-sm"
                onClick={verifyOtp}
              >
                Verify OTP
              </button>
            </div>
          )}
        </form>

        {/* Login with Another Method */}
        <div className="mt-4">
          <p className="text-white text-center mb-4">
            Login using another method
          </p>
          <div className="flex flex-row items-center justify-center">
            <Link to="/login-with-email">
              <FaRegEnvelope size={30} color={"white"} className="ml-4" />
            </Link>
            <GoogleLogin/>
            {/* <FaFacebookSquare size={30}  color={"white"} className='ml-4'/> */}
          </div>

          <div className="flex items-center justify-center">
            <Link
              to="/signup"
              className="inline-block text-white hover:underline focus:outline-none mt-10 text-center"
            >
              Don't Have an Account? SignUp
            </Link>
            <MdArrowForwardIos
              size={20}
              color={"white"}
              className="ml-2 mt-10"
            />
            <p
              id="stellar_copyright"
              className="text-white  absolute bottom-0 "
            >
              © 2023 ViXR Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
