import React from 'react';
import DynamicDropdown from '../Util/DynamicDropdown';

function OrganizationResource() {
    return (
        <div className='text-white'>
            <DynamicDropdown dropdownType="Platform Assets"/>
            <br/>
            <DynamicDropdown dropdownType="Platform Experiences"/>
        </div>
    );
}

export default OrganizationResource;