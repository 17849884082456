import React from "react";
import PropTypes from 'prop-types';

function InputWithLabel({ label, data }) {
  return (
    <div className="flex flex-row items-start justify-start p-4   mt-8">
      <p className="Label text-sm text-left uppercase mt-2 text-white w-40">
        {label}
      </p>

      <div className=" ml-4">
        <input
          className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
          type="text"
          id="organization_name"
          value={data}
        />
      </div>
    </div>
  );
}
InputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.any, // Adjust the type accordingly based on your needs
};
export default InputWithLabel;
