// LoadingSpinner.js
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center text-xl text-gray-800">
      <FaSpinner className="animate-spin mr-2"  size={30}/>
      <span>Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
