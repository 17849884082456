import React from 'react';

function SwitchOrganization(props) {
    return (
        <div>
            SwitchOrganization
        </div>
    );
}

export default SwitchOrganization;