// EmailPasswordLogin.js
import React, { useState } from 'react';
import { FaMobileAlt,FaEye, FaEyeSlash} from 'react-icons/fa';
import { MdArrowForwardIos } from "react-icons/md";
import { Link,useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase';
import {createUserWithEmailAndPassword,} from "firebase/auth";
import GoogleLogin from './GoogleLogin';
import toast from 'react-hot-toast';
import emailValidator from 'email-validator';

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate=useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = async () => {
     // Basic validation for non-empty fields
     if (!email || !password) {
      setError('Please enter both email and password.');
    } else if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
    } else if (!isValidPassword(password)) {
      setError('Password must be at least 8 characters long and include letters and numbers.');
    } else {
        // Successful signup
        setError('');
        try {
          await createUserWithEmailAndPassword(auth, email, password);
          setEmail('');
          setPassword('');
          navigate("/login-with-email");
        } catch (error) {
          toast.error('Error creating user:', error.message);
        }
       
      }
  
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError(''); // Clear error when the user starts typing
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(''); // Clear error when the user starts typing
  };

  const isValidEmail = (email) => {
   
    return  emailValidator.validate(email);
  };

  const isValidPassword = (password) => {
    // Password must be at least 8 characters long and include letters and numbers
    return password.length >= 8 && /[a-zA-Z]/.test(password) && /\d/.test(password);
  };

  return (
    
    <div className="flex items-center justify-center h-screen">
         <div>
     
      
     
    </div>
    <div className="max-w-md mx-auto mt-8 p-6 ">
        <h2 className='text-white font-bold ' 
         style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
        > SignUp to ViXR</h2>
        <br/><br/>
     
        <div className="mb-4">
          <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
            Email
          </label>
          <input type="email" placeholder="Email" onChange={handleEmailChange}  className="w-full p-2 text-white border-b-[2px] bg-transparent" />
        </div>
        <div className="mb-4">
          <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
           Password
          </label>
          <div className='relative'>
          <input  type={showPassword ? 'text' : 'password'} placeholder="Password" onChange={handlePasswordChange}  className="w-full p-2 text-white border-b-[2px] bg-transparent"/>
          <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-transparent border-none cursor-pointer focus:outline-none"
      >
        {showPassword ?  <FaEye size={20} className='text-white' /> : <FaEyeSlash size={20} className='text-white'/>}
      </button>
      </div>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <p className='text-login-label text-sm mb-4'>By proceeding you confirm to Agree to ViXR Terms of Use</p>
        <button className="bg-default-button text-white p-2 rounded-md w-full button-sm" onClick={handleSignup}>Sign Up</button>
     

      {/* Login with Another Method */}
      <div className="mt-4">
      <p className="text-white text-center mb-4">SignUp using another method</p>
      <div className="flex flex-row items-center justify-center">
      <Link to="/"><FaMobileAlt size={30}  color={"white"} className='ml-4'/></Link>
      <GoogleLogin />
      </div>
     
       
        <div className="flex items-center justify-center">
          <Link
         
          to="/"
          className="inline-block text-white  focus:outline-none mt-10 text-center"
          
        >
          Already a user ? <span className='underline'>Login</span>
          </Link>
          <MdArrowForwardIos size={20}  color={"white"} className='ml-2 mt-10'/>
          <p id="stellar_copyright"className='text-white  absolute bottom-0 '>© 2023 ViXR Inc.</p>
        </div>
       
       
      </div>

    
    </div>
    
    </div>
  );
};

export default SignupForm;


