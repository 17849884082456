import React from 'react';
import { Link } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";

function SignupFormWithAddOrganization(props) {
    return (
        <div className="flex items-center justify-center h-screen">
        <div className="max-w-md mx-auto mt-8 p-6 ">
            <h2 className='text-white font-bold ' 
             style={{ fontFamily: 'Manrope', fontSize: '36px', fontWeight: '600px' }}
            >Create your Organization on ViXR</h2>
            <br/><br/>
          <form>
            {/* Phone Number Input */}
            <div className="mb-4">
              <label className="block text-login-label text-sm font-bold mb-2" htmlFor="phoneNumber">
               Create Organization
              </label>
              <div className='flex'>
              <input
                className="w-full p-2 text-login-label border-b-[2px] bg-transparent"
                type="text"
                id="organization_name"
                placeholder=""
              />
              <FaCheck size={20} color="green" className='ml-4 mt-8'/>
              </div>
            </div>
            <div className='flex'>
            <RxCross2 size={20} color="red"/>
            <p className='text-white text-sm mb-4'>This organization name has already been taken</p>
            </div>
            <div className="flex justify-center items-center">
           <Link to="/signup-add-organization-detail"> <button className="bg-default-button  text-white p-2 rounded-md w-40 button-sm" type="submit">
              Next
            </button></Link>
            </div>
          </form>
    
          
    
        
        </div>
        
        </div>
    );
}

export default SignupFormWithAddOrganization;