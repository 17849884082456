import React, { useEffect, useState } from "react";
import { api2 } from "../../api/axiosConfig";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const OrganizationProfile = () => {
  const [formData, setFormData] = useState({
    slug: "",
    domain: "",
    name: "",
    is_enable: true,
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const params = useParams();

  useEffect(() => {
    // Define the specific endpoint for this component
    const endpoint = `/tenant/get`;

    // Use the configured Axios instance to make the API call
    const payload = { slug: params.slug };
    api2
      .post(endpoint, payload)
      .then((response) => {
        const { slug, domain, name, address } = response.data.data;
        const { line1, line2, city, state, country, zip_code } = address;

        setFormData((prevData) => ({
          ...prevData,
          slug,
          domain,
          name,
          address: {
            line1,
            line2,
            city,
            state,
            country,
            zip_code,
          },
        }));
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const validateForm = () => {
    const newErrors = {};

    const validateField = (field, validationFunction, errorMessage) => {
      const trimmedValue = formData[field];
      const isValid = validationFunction(trimmedValue);
      if (!isValid) {
        newErrors[field] = errorMessage;
      }

      return isValid;
    };

    const isAlphanumeric = (value) => /^[a-zA-Z0-9\s]+$/.test(value);
    const isAllowedSpecialCharacters = (value) =>
      /^[a-zA-Z0-9\s/-]+$/.test(value);
    

    const valid =
      validateField(
        "name",
        isAlphanumeric,
        "Name is required and should be alphanumeric"
      ) &&
      validateField(
        "address.line1",
        isAllowedSpecialCharacters,
        "Address Line 1 is required and should be alphanumeric with - and / allowed"
      ) &&
      validateField(
        "address.line2",
        isAllowedSpecialCharacters,
        "Line 2 is required and should be alphanumeric with - and / allowed"
      ) &&
      validateField(
        "address.city",
        isAlphanumeric,
        "City is required and should be alphanumeric"
      ) &&
      validateField(
        "address.state",
        isAlphanumeric,
        "State is required and should be alphanumeric"
      ) &&
      validateField(
        "address.country",
        isAlphanumeric,
        "Country is required and should be alphanumeric"
      );
   

    setErrors(newErrors);
    return valid;
  };

  const validateGeneric = (value, regex) => !value.trim() || !regex.test(value);

  const validateField = (name, value) => {
    switch (name) {
      case "name":
        return validateGeneric(value, /^[a-zA-Z0-9\s]+$/)
          ? "Name is required and should be alphanumeric"
          : "";
      case "line1":
      case "line2":
        return validateGeneric(value, /^[a-zA-Z0-9\s/-]+$/)
          ? `${name} is required and should be alphanumeric with - and / allowed`
          : "";
      case "city":
      case "state":
      case "country":
        return validateGeneric(value, /^[a-zA-Z0-9\s]+$/)
          ? `${name} is required and should be valid`
          : "";

      default:
        return "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the field is 'zip_code', convert the value to a number
    const sanitizedValue =
      name === "zip_code" ? value.replace(/\D/g, "") : value;

    const error = validateField(name, sanitizedValue);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: sanitizedValue,
      address:
        name === "name"
          ? prevFormData.address
          : { ...prevFormData.address, [name]: sanitizedValue },
    }));
  };

  // Handler for form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      api2
        .post(`/tenant/update`, formData)
        .then((response) => {
          console.log("Profile updated successfully:", response.data);
          // Optionally, you can update state or perform additional actions after a successful update
        })
        .catch((error) => console.error("Error updating profile:", error));
    } else {
      // Form is invalid, do something (e.g., display error messages)
      toast.error("Form validation failed");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  // Function to toggle between edit and view mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  return (
    <div>
      <div className="flex    text-center text-white itms-center justify-between">
        {isEditMode ? (
          <div className="flex">
            <button
              className=" ml-4 bg-default-button text-white h-10 py-2 px-4 rounded "
              onClick={handleFormSubmit}
            >
              Update
            </button>
            <button
              className=" ml-4 bg-grey-button text-white h-10 py-2 px-4 rounded "
              onClick={toggleEditMode}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex">
            <button
              className=" ml-4 bg-default-button text-white h-10 py-2 px-4 rounded "
              onClick={toggleEditMode}
            >
              Edit
            </button>
            <button className=" ml-4 bg-delete-button text-white h-10 py-2 px-4 rounded ">
              Delete
            </button>
          </div>
        )}
      </div>
      {formData && (
        <div className="p-8">
          <form>
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Slug
              </p>

              <div className=" ml-4">
                <input
                  disabled
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_slug"
                  placeholder={formData.slug}
                  name="slug"
                  value={formData.slug}
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                domain
              </p>

              <div className=" ml-4">
                <input
                  disabled
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_domain"
                  name="domain"
                  value={formData.domain}
                  placeholder={formData.domain}
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-40"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Basic Details
              </p>
            </div>
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                name
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder={formData.name}
                />
              </div>
            </div>
            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Address
              </p>
            </div>
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Line1
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_line1"
                  name="line1"
                  value={formData.address.line1}
                  onChange={handleInputChange}
                  placeholder={formData.address.line1}
                />
              </div>
            </div>
            {errors.line1 && <p style={{ color: "red" }}>{errors.line1}</p>}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                line2
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_line2"
                  name="line2"
                  value={formData.address.line2}
                  onChange={handleInputChange}
                  placeholder={formData.address.line2}
                />
              </div>
            </div>
            {errors.line2 && <p style={{ color: "red" }}>{errors.line2}</p>}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                city
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_city"
                  name="city"
                  value={formData.address.city}
                  onChange={handleInputChange}
                  placeholder={formData.address.city}
                />
              </div>
            </div>
            {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                State
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_state"
                  name="state"
                  value={formData.address.state}
                  onChange={handleInputChange}
                  placeholder={formData.address.state}
                />
              </div>
            </div>
            {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Zip Code
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_zip_code"
                  name="zip_code"
                  value={formData.address.zip_code}
                  onChange={handleInputChange}
                  placeholder={formData.address.zip_code}
                />
              </div>
            </div>
            {errors.zip_code && (
              <p style={{ color: "red" }}>{errors.zip_code}</p>
            )}
            <div className="flex flex-row items-start justify-start    mt-8">
              <p
                className="Label text-left uppercase mt-2 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                country
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label  border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="organization_country"
                  name="country"
                  value={formData.address.country}
                  onChange={handleInputChange}
                  placeholder={formData.address.country}
                />
                <input
                  type="hidden"
                  name="is_enabled"
                  value={formData.is_enable}
                />
              </div>
              {errors.country && (
                <p style={{ color: "red" }}>{errors.country}</p>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default OrganizationProfile;
