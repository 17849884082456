
import React, { useEffect, useState } from 'react';
import {api2, setAccessToken} from '../../api/axiosConfig';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';


const OrganizationList = () => {
  const isMobile = window.innerWidth <640;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const endpoint = '/tenant/list';
    const fetchData = async () => {
      try {
         // After successful login, retrieve and use the access token
        // Set the access token in the Axios headers
        setAccessToken(localStorage.getItem("accessToken"));
        const response = await api2.post(endpoint);
        setData(response.data.data.tenants);
        setLoading(false);
      } catch (error) {
        
        setLoading(false);
      }
    };
  
    fetchData(); // Call the async function
  
    // Other useEffect dependencies, if any
  }, []);
  
  if (loading) {
    return <LoadingSpinner/>;
  }

 
  return (
    <div className='w-full mb-10'>
    <div className={` ${isMobile ? 'hidden' : ''}`}>
      {/* Render your data here */}
      {data && (
        <>
        <ul>
            <li className=' flex m-10 text-grey-button '>
             <p className="flex-1">Name</p>
              <p className="flex-1">Slug</p><p className="flex-1">Created By</p> <p className="flex-1">Created At</p>
                <p className="flex-1 ">Status</p>
                <button className="flex-1 ml-4   rounded "><Link to="#">Actions</Link></button>
            </li>
           
          {data.map(item => (

            <li className='text-white flex m-10 ' key={item.slug}>
                <p className="flex-1">{item.name}</p> <p className="flex-1">{item.slug}</p><p className="flex-1">{item.created_by_name}</p> <p className="flex-1">{item.created_at}</p>
                {item.is_enable?<p className="flex-1 text-text-green">Enabled</p>:<p className="flex-1 ">Disabled</p>}
                <button className="flex-1 ml-4 text-logo-color  rounded "><Link to={`${item.slug}/profile`}>View Details</Link></button>
            </li>
          
          ))}
        </ul>
        <br/>
        <br/>
         <hr className='w-full border-bg-black border-t-2 shadow-md mb-4'/>
         </>
      )}
    </div>
    <div className={`mb-50 ${isMobile ? '' : 'hidden'}`}>
    <ul className='flex flex-col '>
    {data.map(item => (

<li className='text-white   m-5' key={item.slug}>
    <p>Name- {item.name}</p>
     <p>Slug- {item.slug}</p>
     <p>Created By- {item.created_by_name}</p> 
     <p>Created At- {item.created_at}</p>
    <p>Action- { item.is_enable?<span>Enabled</span>:<span>Disabled</span>}</p>
    <button className=" text-logo-color  rounded "><Link to={`${item.slug}/profile`}>View Details</Link></button>
</li>

))}
</ul>
    </div>
    </div>
  );
};

export default OrganizationList;
