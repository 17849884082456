import React from "react";


function Privacypolicy() {
 
    return (
        <div className="bg-left-panel text-white  p-5">
          <h1 className="text-3xl text-center pt-10 font-bold mb-4 text-logo-color">ViXR INCORPORATION</h1>
          <h2 className="text-xl text-center pt-4 font-bold mb-4 text-white">ViXR INCORPORATION</h2>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Data Protection Declaration</h2>
            <p>
            This Data Protection Declaration Applies to the collection, processing and use of User data of related to the Games and the related in-App Services ("Services") provided by www.vixr.io ("ViXR Inc."). The Services enable Users to use the applications and play the Games. ViXR Inc. respects your privacy rights. For details please see our Data Protection Declaration below.

            </p>
          </section>
    
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">1. Scope of Application</h2>
            <p>
            This Data Protection Declaration applies to all Services operated by ViXR Inc. by this App and addresses all Users of the Services ("Users" or "you"). If other offers of ViXR Inc. have a differing data protection declaration, the differing data protection declaration published on that offer shall apply. The Services may contain cross-references ("links") to offers of third party providers. By clicking on such links, you leave the Services. In such a case, ViXR Inc. does not take any responsibility for the content and compliance with data protection laws of these offers. This Data Protection Declaration does not apply to those offers.

            </p>
          </section>
    
          {/* Repeat the structure for other sections as needed */}
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">2. Collection, processing and use of your data for the Services</h2>
            <p>
            (1) ViXR Inc. will only collect, process or use your data pursuant to the Applicable data protection laws, such as the Indian Data Protection Law.
            </p>
            <p>
            (2) ViXR Inc. will only collect, process or use any data referring to you as a person for the purpose of fulfilling the user contract, i.e. the establishment, performance and ending of the contract (including billing the Services). ViXR Inc. will not disclose your personal data to third parties unless ViXR Inc. is required by law or unless you have consented to any other use. Collection, usage and processing of the data are carried out in electronic form.
            </p>
            <p>
            (3) Personal data which may be collected by ViXR Inc. for the purpose of fulfilling the user contract. Any additional data you provide to ViXR Inc. for completing your profile or when uploading content, you provide voluntarily to ViXR Inc..
            </p>
            <p>ViXR Inc. also uses personal data in the course of ending the contractual relationship with a User to the extent necessary to terminate the obligatory relationship.</p>
          <p>(4) ViXR Inc. may give Users the opportunity to register for the Services via Facebook Connect and Twitter. In this case, ViXR Inc. may collect, process or use data, the User published in his or her Facebook profile. Vice versa ViXR Inc. may submit information on the participation in the Services to Facebook and Twitter. Such information and other personal data may be made publicly accessible on Facebook or Twitter for other Facebook and Twitter users. Please review the data protection declaration of Facebook and Twitter on this.</p>
          <p>(5) When using the Services, ViXR Inc. may collect information on how you use the Services in order to enable and bill for the proper and contractual use of the Services ("usage data"). Usage data is, in particular, characteristics for purposes of identifying the User, information concerning the beginning and end, as well as the scope of the respective use, IP-address, used device, a unique identifier of the device and information concerning the Services used by the User. For example, the location of the Users could be identified and, when communicating with other Users of the Services, the location could be transmitted to these other Users together with the information you send to them.</p>
          <p>(6) ViXR Inc. may collect and use payment data insofar as this is required for billing. In order to conduct In-App purchases such as the purchase of virtual goods, ViXR Inc. currently works with payment-providers such as Google, Apple, Nokia or Microsoft. In this case, ViXR Inc. is entitled to transfer any data which is required for billing the Services via a third party payment provider to that provider, if applicable. Some payment providers may collect the necessary billing data directly from the User. In such cases, the terms and conditions and data protection declaration of those payment providers apply. Further to that, ViXR Inc. will not pass on User data, or the content of private messages in Services, to any third party unless required by law or if the User has consented to it.</p>
         
          </section>
    
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">3. Advertising</h2>
            <p>
            (1) Services may be partly financed through advertising. Advertising messages may therefore appear throughout the use of the Services. ViXR Inc. may display personalized advertising matched to the actions you perform and content you upload when using the Services.
            </p>
            <p>
            (2) ViXR Inc. will only send third party advertising to your e-mail address upon your prior consent.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">4. Data logging for internal system purposes and statistical purposes</h2>
            <p>
            ViXR Inc. may automatically records information, namely usage data. For advertising, market research, improvement of existing and creation of new innovative products, ViXR Inc. may create aggregated anonymized data. Besides that, ViXR Inc. may use a tracking tool, such as Flurry Analytics and Google Analytics to track the usage of the Services in order to optimize the Services for the Users. All data is used in anonymized form.

            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">5. Data Security</h2>
           <p>
           ViXR Inc. would like to point out that data protection and data security cannot be guaranteed for data transmissions outside of our sphere of authority, such as your mobile network. You are aware that your mobile carrier, or unauthorized third parties may have access to the data stored on the servers or transmitted through ViXR Inc. and used in the Services.

            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">6. Rights of Users</h2>
            <p>
            ViXR Inc. does not store any user data. Furthermore you can contact ViXR Inc. if you want to correct your data because it is false or if you request the blocking or deletion data with reference to your person.
            </p>
            <p>
            In such cases, please send ViXR Inc. an e-mail with your request to: support@vixr.io.

            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">7. Declaration of Consent</h2>
           <p>
           By using the Services, you consent to the above mentioned collection, processing and use of your data.


            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">8. Amendment of the Privacy Policy</h2>
           <p>
           ViXR Inc. reserves the right to amend this Privacy Policy at any time, but ViXR Inc. will always preserve the principles of data protection as stated above. Therefore, ViXR Inc. recommends that Users review the current Privacy Policy regularly, which will be published on the respective website. ViXR Inc. will inform the Users in advance before a use of personal data exceeding this declaration takes place.
            </p>
          </section>
         
          <footer className="mt-8 text-sm text-gray-600">
            Mercer Island, WA US | Contact: support@vixr.io
          </footer>
        </div>
      );
};

export default Privacypolicy;

