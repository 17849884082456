import React from 'react';
import OrganizationList from '../components/Organization/OrganizationList';
import { Link } from 'react-router-dom';

function TenantPage(props) {
    return (
        <>
        <div className='flex flex-col lg:flex-row p-8 mt-5  text-center text-white itms-center justify-between'>
           <h1 className='text-3xl mb-4 lg:mb-0'>Organization</h1> 
           <Link to='/signup-add-organization-detail'>
           <button className='bg-default-button text-white py-2 px-4 rounded mr-4'>CREATE NEW ORGANIZATION</button>
           </Link>
        </div>
        <OrganizationList/>
        </>
    );
}

export default TenantPage;