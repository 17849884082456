import React from 'react';
import { Link } from 'react-router-dom';


const LoginLeftPanel=()=>{
    return(
        <div className="m-0 w-4/6 bg-left-panel hidden lg:block overflow-y-auto">
       
      
         <Link to="/"><h2 className="ml-2 p-4 font-manrope text-logo-color text-left w-auto"
         style={{ fontWeight: '300px', fontSize: '36px' }}>ViXR</h2></Link>
          <div
        
         style={{ textAlign: "center" }}>
         <p
           className="font-Manrope text-white"
           style={{ display: 'block',fontFamily: 'Manrope', fontSize: '32px', fontWeight: '600px', width: '100%' }}
         >
           Welcome
         </p>
         <p
           className="mt-3 text-white font-Manrope"
           style={{ fontFamily: 'Manrope', fontSize: '24px', fontWeight: '600px' }}
         >
          <p>
          Some text Some text Some text Some text Some text <br />
          Some text Some text Some text Some text Some text{" "}
          </p>
            
          
         </p>
         <img
           src="images/img_image1.png"
           className=" h-auto w-3/5 m-auto object-cover lg:w-3/5"
           alt="imageOne"
         />
         <p
           className="mt-3 text-white"
           style={{ fontFamily: 'Manrope', fontSize: '24px', fontWeight: '600px', display: "block" }}            >
           <p>
             Some text Some text Some text Some text Some text <br />
             Some text Some text Some text Some text Some text{" "}
           </p>
         </p>
       </div>
       </div>
    )

}

export default LoginLeftPanel;